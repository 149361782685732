import i18n from "@/i18n";
import store from "@/store";
import {
    getAvailableNoticeTemplateList,
    getNoticeTemplateBody,
    getNoticeTemplatePackItems,
    sendMail,
} from "@/store/services/task/contactMechanism";
import {ComboBox, ComboBoxItem, ContactType, EmailMessage, NoticeTemplateSelectionContext,} from "@/types";
import {downloadFile} from "../../offerService";
import {settings} from "@/settings";
import {api} from "@/auth/api";
import Notify from 'quasar/src/plugins/Notify.js';;
import {contacts} from "@/components/summary-panel/summary";

export async function getTemplates(currentRoute: string) {
        let request: NoticeTemplateSelectionContext = {
            applicationName: '',
            context: '',
            maxResults: 100,
            dataInfo: '',
        }
        switch (currentRoute) {
            case 'demand-edit':
                request = {
                    applicationName: 'TWDDistribution',
                    context: 'offerFinancing',
                    maxResults: 100,
                    dataInfo: '',
                }
                break;
            case 'order-edit':
                request = {
                    applicationName: 'ORDER',
                    context: 'DISTRIBUTION',
                    maxResults: 100,
                    dataInfo: '',
                }
                break;
            case "TaskDetail":
                if (store.getters['taskModule/isTaskModuleOffer']) {
                    request = {
                        applicationName: 'TWDDistribution',
                        context: 'offerFinancing',
                        maxResults: 100,
                        dataInfo: '',
                    }
                } else {
                    request = {
                        applicationName: 'ORDER',
                        context: 'DISTRIBUTION',
                        maxResults: 100,
                        dataInfo: '',
                    }
                }
                break;
            case 'third-edit':

                request = {
                    applicationName: 'PARTY',
                    context: 'PARTY',
                    maxResults: 100,
                    dataInfo: '',
                }
                break

        }
    try {
            if(request.context){
                const templates = await getAvailableNoticeTemplateList(request)
                for ( const template of templates ) {
                    template.selected = true
                    if ( template.objectType === 'odm.contactmechanism.noticetemplate.packnoticetemplate' ) {
                        const packItems: any = await getNoticeTemplatePackItems(request, template.resourceUid || '')
                        if ( template.items ) {
                            for ( let i = 0; i < packItems.items.length; i++ ) {
                                packItems.items[i].resourceUid = template.items[i].itemTemplate?.resourceUid;
                                packItems.selected = true;
                                packItems.items[i].selected = true;
                            }
                        }
                        template.isPack = true
                        template.items = [];
                        template.items = packItems.items
                    }
                }
                return templates
            }else{
                Notify.create({
                    message: 'Templates not found',
                    color: 'negative'
                })
            }

    } catch (e) {
        return Promise.reject(e)
    }

}

export function handleSendPrintRequest(entityType: string, templateData: any, entityId: string, roleCode: string) {
    switch (entityType) {
        case 'offer':
            return handleOfferSendPrintRequest(templateData, entityId, roleCode)
        case 'order':
            return handleOrderSendPrintRequest(templateData, entityId, roleCode)
        case 'party':
            return handlePartySendPrintRequest(templateData, entityId, roleCode)
    }
}

function handleOfferSendPrintRequest(templateData: any, entityId: string, roleCode: string) {
    let fileCodeList = []
    let reports = []
    if(templateData.length > 0){
        templateData.forEach((main: any) => {
            if(main.isPack && main.selected) {
                main.items.forEach((item: any) => {
                    if(item.selected) {
                        reports.push({
                            "fileCode": item.resourceUid,
                            "dataServiceQuery": {
                                "queryId": "odm-print-select-offer",
                                "offset": 0,
                                "limit": 100,
                                "parameters": {
                                    "offerId": entityId,
                                    "role_code": roleCode
                                },
                            }
                        })
                    }
                })
            }else if(!main.isPack && main.selected) {
                reports.push({
                    "fileCode": main.resourceUid,
                    "dataServiceQuery": {
                        "queryId": "odm-print-select-offer",
                        "offset": 0,
                        "limit": 100,
                        "parameters": {
                            "offerId": entityId,
                            "role_code": roleCode
                        },
                    }
                })
            }
        })
    }else if(templateData.isPack) {
        fileCodeList = templateData.items.filter((item: any) => item.selected)
        fileCodeList.forEach((item: any) => {
            reports.push({
                "fileCode": item.resourceUid,
                "dataServiceQuery": {
                    "queryId": "odm-print-select-offer",
                    "offset": 0,
                    "limit": 100,
                    "parameters": {
                        "offerId": entityId,
                        "role_code": roleCode
                    },
                }
            })
        })
    } else {
        reports.push({
            "fileCode": templateData.resourceUid,
            "dataServiceQuery": {
                "queryId": "odm-print-select-offer",
                "offset": 0,
                "limit": 100,
                "parameters": {
                    "offerId": entityId,
                    "role_code": roleCode
                }
            }
        })
    }


    const fileCodes = new Set();
    reports = reports.filter(item => {
        if (fileCodes.has(item.fileCode)) {
            return false;
        } else {
            fileCodes.add(item.fileCode);
            return true;
        }
    })


    return {
        "reportName": "fileOffer",
        "reportFormat": "PDF",
        reports
    }
}

function handleOrderSendPrintRequest(templateData: any, entityId: string, roleCode: string) {
    let fileCodeList = []
    let reports = []

    if(templateData.length > 0){
        templateData.forEach((main: any) => {
            if(main.isPack && main.selected) {
                main.items.forEach((item: any) => {
                    if(item.selected) {
                        reports.push({
                            "fileCode": item.resourceUid,
                            "dataServiceQuery": {
                                "queryId": "odm-print-select-order",
                                "offset": 0,
                                "limit": 100,
                                "parameters": {
                                    "orderId": entityId,
                                    "role_code": roleCode
                                },
                            }
                        })
                    }
                })
            }else if(!main.isPack && main.selected) {
                reports.push({
                    "fileCode": main.resourceUid,
                    "dataServiceQuery": {
                        "queryId": "odm-print-select-order",
                        "offset": 0,
                        "limit": 100,
                        "parameters": {
                            "orderId": entityId,
                            "role_code": roleCode
                        },
                    }
                })
            }
        })
    }else if(templateData.isPack) {
        fileCodeList = templateData.items.filter((item: any) => item.selected)
        fileCodeList.forEach((item: any) => {
            reports.push({
                "fileCode": item.resourceUid,
                "dataServiceQuery": {
                    "queryId": "odm-print-select-order",
                    "offset": 0,
                    "limit": 100,
                    "parameters": {
                        "orderId": entityId,
                        "role_code": roleCode
                    },
                }
            })
        })
    } else {
        reports.push({
            "fileCode": templateData.resourceUid,
            "dataServiceQuery": {
                "queryId": "odm-print-select-order",
                "offset": 0,
                "limit": 100,
                "parameters": {
                    "orderId": entityId,
                    "role_code": roleCode
                }
            }
        })
    }


    const fileCodes = new Set();
    reports = reports.filter(item => {
        if (fileCodes.has(item.fileCode)) {
            return false;
        } else {
            fileCodes.add(item.fileCode);
            return true;
        }
    })


    return {
        "reportName": "fileOrder",
        "reportFormat": "PDF",
        reports
    }
}

function handlePartySendPrintRequest(templateData: any, entityId: string, roleCode: string) {
    let fileCodeList = []
    let reports = []

    if(templateData.length > 0){
        templateData.forEach((main: any) => {
            if(main.isPack && main.selected) {
                main.items.forEach((item: any) => {
                    if(item.selected) {
                        reports.push({
                            "fileCode": item.resourceUid,
                            "dataServiceQuery": {
                                "queryId": "odm-print-select-party",
                                "offset": 0,
                                "limit": 100,
                                "parameters": {
                                    "partyId": entityId,
                                    "role_code": roleCode
                                },
                            }
                        })
                    }
                })
            }else if(!main.isPack && main.selected) {
                reports.push({
                    "fileCode": main.resourceUid,
                    "dataServiceQuery": {
                        "queryId": "odm-print-select-party",
                        "offset": 0,
                        "limit": 100,
                        "parameters": {
                            "partyId": entityId,
                            "role_code": roleCode
                        },
                    }
                })
            }
        })
    }else if(templateData.isPack) {
        fileCodeList = templateData.items.filter((item: any) => item.selected)
        fileCodeList.forEach((item: any) => {
            reports.push({
                "fileCode": item.resourceUid,
                "dataServiceQuery": {
                    "queryId": "odm-print-select-party",
                    "offset": 0,
                    "limit": 100,
                    "parameters": {
                        "partyId": entityId,
                        "role_code": roleCode
                    },
                }
            })
        })
    } else {
        reports.push({
            "fileCode": templateData.resourceUid,
            "dataServiceQuery": {
                "queryId": "odm-print-select-party",
                "offset": 0,
                "limit": 100,
                "parameters": {
                    "partyId": entityId,
                    "role_code": roleCode
                }
            }
        })
    }


    const fileCodes = new Set();
    reports = reports.filter(item => {
        if (fileCodes.has(item.fileCode)) {
            return false;
        } else {
            fileCodes.add(item.fileCode);
            return true;
        }
    })


    return {
        "reportName": "fileParty",
        "reportFormat": "PDF",
        reports
    }
}

export async function sendPrintRequest(body:any, entityType: string, packTemplateID?: string) {
    let url = ''
    switch (entityType) {
        case 'offer':
            if(!packTemplateID){
                url = `${settings.api_url}/odm-offer/api/1/odm-offer/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.offerId}/${body.reports[0].fileCode}/`;
            }else{
                url = `${settings.api_url}/odm-offer/api/1/odm-offer/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.offerId}/${packTemplateID}/`;
            }
            break
        case 'order':
            if(!packTemplateID){
                url = `${settings.api_url}/odm-order/api/1/odm-order/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.orderId}/${body.reports[0].fileCode}/`;
            }else{
                url = `${settings.api_url}/odm-order/api/1/odm-order/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.orderId}/${packTemplateID}/`;
            }
            break
        case 'party':
            if(!packTemplateID){
                url = `${settings.api_url}/odm-party/api/1/odm-party/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.partyId}/${body.reports[0].fileCode}/`;
            } else {
                url = `${settings.api_url}/odm-party/api/1/odm-party/generatedDocument/requestNew/${body.reports[0].dataServiceQuery.parameters.partyId}/${packTemplateID}/`;
            }
            break
    }
    return (await api().post(url, body)).data
}

export async function checkDocumentGenerated(entityId: string, templateName: string | undefined, entityType: string, processId?: string) {
    let url = ''
    switch (entityType) {
        case 'offer':
            if(!processId){
                url = `${settings.api_url}/odm-offer/api/1/odm-offer/generatedDocument/checkDocumentGenerated/${entityId}/${templateName}/`;
            }else{
                url = `${settings.api_url}/odm-offer/api/1/odm-offer/generatedDocument/checkDocumentGenerated/${processId}/`;
            }
            break
        case 'order':
            if(!processId){
                url = `${settings.api_url}/odm-order/api/1/odm-order/generatedDocument/checkDocumentGenerated/${entityId}/${templateName}/`;
            }else{
                url = `${settings.api_url}/odm-order/api/1/odm-order/generatedDocument/checkDocumentGenerated/${processId}/`;
            }
            break
        case 'party':
            if(!processId){
                url = `${settings.api_url}/odm-party/api/1/odm-party/generatedDocument/checkDocumentGenerated/${entityId}/${templateName}/`;
            }else{
                url = `${settings.api_url}/odm-party/api/1/odm-party/generatedDocument/checkDocumentGenerated/${processId}/`;
            }
            break
    }
    return (await api().get(url)).data
}

export async function getMail(entity: any, entityType: string): Promise<ComboBox> {
    const comboEmail: ComboBoxItem[] = []
    if(entityType === 'offer'){
        entity.associatedParties?.forEach((p: any) => {
            if (p.role_code === "CUSTOMER" && p.associatedParty?.third?.type.id === 'party-FrenchOrganization'){
                p.associatedParty?.third?.correspondents.forEach((b:any) => {
                    b.contactCorrespondent.filter((c:any) =>
                        c.contactMechanism.type.id === ContactType.EMAIL_CONTACT)
                        .forEach((c:any) => {
                            const emailLabel = b.qualificationType?.type?.value
                            comboEmail.push({
                                value: `${c.contactMechanism.emailAddress}`,
                                label: `${emailLabel}: ${c.contactMechanism.emailAddress}`,
                            })
                        })
                })
            }
            else if ((p.role_code === "CUSTOMER" && p.associatedParty?.third?.type.id === 'party-FrenchPerson' ) || p.role_code === "DIRG" ){
                p.associatedParty?.third?.contacts.filter((c:any) => c.contactMechanism.type.id === 'EMAIL_CONTACT')
                    .forEach((c:any) => {
                        const emailLabel = `demand.party.roles.${p.role_code}`
                        comboEmail.push({
                            value: `${c.contactMechanism.emailAddress}`,
                            label: `${i18n.global.t(emailLabel)}: ${c.contactMechanism.emailAddress}`,
                        })
                    })
            }
        })
    } else if (entityType === 'order'){
        entity.orderParties?.forEach((p: any) => {
            if ((p.orderParty.role.resourceUid === "CUSTOMER" && p.orderParty?.third?.type.id === 'party-FrenchOrganization')
                ||  ((p.orderParty.role.resourceUid === "CUSTOMER" && p.orderParty?.third?.type.id === 'party-FrenchPerson' ) || p.orderParty.role.resourceUid === "DIRG" )){
                p.orderParty?.third?.person.contacts.forEach((b:any) => {
                    if(b.contactMechanism.type.id === 'EMAIL_CONTACT'){
                        const emailLabel = `demand.party.roles.${p.orderParty.role.resourceUid}`
                        comboEmail.push({
                            value: `${b.contactMechanism.emailAddress}`,
                            label: `${i18n.global.t(emailLabel)}: ${b.contactMechanism.emailAddress}`,
                        })
                    }
                })
            }
        })
    } else if(entityType === 'party'){
        const isCustomer = entity.person?.roles.filter((r: any) => r.role.type.value === 'CUSTOMER' || r.role.type.value === 'DIRG')
        if(isCustomer.length > 0){
            entity.person?.contacts?.map((p: any) => {
                if(p.contactMechanism.type.id === ContactType.EMAIL_CONTACT){
                    const emailLabel = 'demand.party.roles.CUSTOMER'
                    comboEmail.push({
                        value: `${p.contactMechanism.emailAddress}`,
                        label: `${i18n.global.t(emailLabel)}: ${p.contactMechanism.emailAddress}`,
                    })
                }
            })
        }

    }

    return {items: comboEmail};
}

export async function sendMailWithAllDocument(emailSendList: string[], fileId: string) {
    const attachments = []
    const file = await downloadFile(fileId)
    attachments.push(
            {
                objectType: "odm.contactmechanism.emailattachment",
                systemUid: "odm-contactmechanism",
                resourceUid: "signatory-checking",//res.document.resourceUid,
                attachment: {
                objectType: "contactmechanism-emailattachment",
                    systemUid: "odm-contactmechanism",
                    resourceUid: "signatory-checking", //res.document.resourceUid,
                },
                label: "file.pdf",
                originalFileName: "file.pdf",
                format: "application/pdf",
                content: file.content,
            }
    )

    const body: EmailMessage = {
        objectType: "odm.contactmechanism.communicationrequest.emailmessage",
        clientApplication: {
          systemUid: "inttest",
          objectType: "esign-ESignClient",
          resourceUid: "ApplicationClient",
        },
        emailTemplate: {
          objectType: "odm.contactmechanism.emailtemplate",
          systemUid: "odm-contactmechanism",
          resourceUid: "SendNoticeTemplate",
        },
        parameters: {
          signatory_name: emailSendList.join(),
          signatory_email: emailSendList.join().replace(",", ";"),
        },
        businessData: null,
        relatedObjects: null,
        attachments
    };

    await sendMail(body)
    
}

export async function getNoticeBody(): Promise<string>{
    const result = await getNoticeTemplateBody()
    return result.body
}

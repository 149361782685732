
import SummaryPerson from "@/components/summary-panel/SummaryPerson.vue";
import SummaryOrganization from "@/components/summary-panel/SummaryOrganization.vue";
import { Options, Vue } from "vue-class-component";
import { OfferAssociatedParty, Order, OrderAssociatedParty } from "@/types";

@Options({
  components: { SummaryPerson, SummaryOrganization },
  props: ["arrParties"],
})
export default class OrderSummaryParty extends Vue {
  arrParties?: Array<OrderAssociatedParty>;
  tab?: string = "";
  filteredParties?: Array<OrderAssociatedParty>;
  get buildParties() {
    if (this.arrParties) {
      this.filteredParties = this.arrParties.filter((el) => el.orderParty?.role?.resourceUid !== "DIRG");
      if (this.filteredParties.length>0){
        this.tab = this.filteredParties[0].orderParty?.resourceUid;
      }
      return this.filteredParties;
    }
    return [];
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row mt-4 mb-2 font-weight-bold",
  style: {"font-size":"16px","text-decoration":"underline"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-7" }
const _hoisted_4 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_5 = { class: "font-weight margin-left color" }
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_8 = { class: "font-weight margin-left color" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_11 = { class: "font-weight margin-left color" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-3" }
const _hoisted_14 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_15 = { class: "font-weight margin-left color" }
const _hoisted_16 = { class: "col-4" }
const _hoisted_17 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_18 = { class: "font-weight margin-left color" }
const _hoisted_19 = { class: "col-2" }
const _hoisted_20 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_21 = { class: "font-weight margin-left color" }
const _hoisted_22 = { class: "col-3" }
const _hoisted_23 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_24 = { class: "font-weight margin-left color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.label')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderItems, (orderItem) => {
      return (_openBlock(), _createElementBlock("div", {
        key: orderItem.resourceUid
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.brand')), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getAssets(orderItem).brand), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.color')), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getAssets(orderItem).color), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.matriculation')), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getAssets(orderItem).matriculation), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.NumFab')), 1),
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getAssets(orderItem).numFab), 1)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.chassis')), 1),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.getAssets(orderItem).chassisReference), 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.energie')), 1),
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.getAssets(orderItem).engineType), 1)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('demand.tabs.summary.vehicle.matriculationDate')), 1),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.getAssets(orderItem).matriculationDate), 1)
          ])
        ])
      ]))
    }), 128))
  ], 64))
}
import {Order, OrderAsset, OrderAssetComponent, OrderVehicle, ProposalAssetItemType} from "@/types";
import {deepClone, getEntity} from "@/utils";
import {formatDateTimeLocale, formatWithMask} from "@/utils/configuration/formatters-config";
import {completeProposalAssetCarInfo} from "@/store/services/asset/assetMapper";
import store from "@/store";

export const convertProductAssetToOrderAsset = (asset: any,orderItem:any): OrderVehicle => {
    const {
        description,
        flagNew,
        flagVD,
        color,
        gearBox,
        matriculation,
        matriculationDate,
        constructionDate,
        currentMileage,
        chassisReference,
        amountWoTax,
        discountPercentage,
        discountAmount,
        taxAmount,
        amountWTax,
        totalAmountWoTax,
        totalTaxAmount,
        totalAmountWTax,
        assetProduct,
        packAssetProduct,
        horsePower,
        purchaseAmountWoTax,
        purchaseAmountWTax,
        
    } = asset
    let brand = ''
    let range = ''
    let version = ''
    let carBodyStyle = ''
    let engineType = ''
    const orderAssetComponents = convertAssetItemToOrderAssetComponent(asset)
    const config = asset.config
    const supplier: any = {
        party: {
            systemUid: 'odm-party',
        },
        role: {
            objectType: 'odm.order.orderpartyrole',
            systemUid: 'odm-order',
        },
        objectType: 'odm.order.orderparty',
        systemUid: 'odm-order',
    }
    let supplierPartyName = undefined
    const formBind = asset.config?.formBind
    if (formBind) {
        const assetSupplier = formBind.supplier
        if (formBind.supplier) {
            supplier.party.resourceUid = assetSupplier.party.resourceUid
            supplier.party.objectType = assetSupplier.party.objectType
            supplier.role.resourceUid = assetSupplier.role.resourceUid
            supplier.resourceUid = assetSupplier.resourceUid
        }
        supplierPartyName = asset.config&&asset.config.supplierPartyName ?asset.config.supplierPartyName :''
        brand = formBind.brand?formBind.brand.id :''
        range = formBind.range?formBind.range.id :''
        version = formBind.version?formBind.version.id :''
        carBodyStyle = formBind.carBodyStyle?formBind.carBodyStyle.id :''
        engineType = formBind.engineType?formBind.engineType.id :''
    }
    let assetDelivery:any  = undefined
    //if (asset.config?.assetDeliveryModel && (asset.config?.assetDeliveryModel.deliveryDate || asset.config?.assetDeliveryModel.description)) {
     if (asset.config?.assetDeliveryModel) {
        const assetDeliveryModel = asset.config?.assetDeliveryModel
        //const addressId = await upsert('odm-party', address, 'address');
       /* if(orderItem.initialDeliveryDate){
            orderItem.initialDeliveryDate =  formatDateTimeLocale(orderItem.initialDeliveryDate, "DD/MM/YYYY HH:mm", "us", "YYYY-MM-DD HH:mm")
        }
        if(orderItem.previsionDeliveryDate){
            orderItem.previsionDeliveryDate =  formatDateTimeLocale(orderItem.previsionDeliveryDate, "DD/MM/YYYY HH:mm", "us", "YYYY-MM-DD HH:mm")
        }
        if(assetDeliveryModel.effectiveDeliveryDate){
            assetDeliveryModel.effectiveDeliveryDate = formatDateTimeLocale(assetDeliveryModel.effectiveDeliveryDate, "DD/MM/YYYY HH:mm", "us", "YYYY-MM-DD HH:mm")
        }   */   
                
        assetDelivery= {
            objectType: 'odm.order.orderdeliverydetails',
            systemUid: 'odm-order',
            effectiveDeliveryDate: assetDeliveryModel.effectiveDeliveryDate,
            previsionDeliveryDate:orderItem.previsionDeliveryDate,
            initialDeliveryDate:orderItem.initialDeliveryDate,
            deliveryAddress: undefined, //assetDeliveryModel.deliveryAddress, TODO review
            description: assetDeliveryModel.description,
        }
        if(!orderItem.orderItemParent){
            assetDelivery.process={resourceUid:'ORDER'} 
        }
    }
    return {
        config:config,
        objectType: "odm.order.orderasset.ordergood.ordervehicle.ordercar",
        quantity: 1,
        description,
        discountPercentage,
        amountWoTax,
        discountAmount,
        taxAmount,
        amountWTax,
        totalAmountWoTax,
        totalTaxAmount,
        totalAmountWTax,
        assetProduct,
        packAssetProduct,
        supplier,
        orderAssetComponents,
        assetDelivery,
        flagNew,
        flagVD,
        color,
        gearBox,
        matriculation,
        matriculationDate,
        constructionDate,
        currentMileage,
        chassisReference,
        brand,
        range,
        version,
        carBodyStyle,
        engineType,
        horsePower,
        purchaseAmountWoTax,
        purchaseAmountWTax,
        
    }
}


export const convertOrderVehicleToProductVehicle = async (orderVehicle: OrderVehicle) => {
    const {
        description,
        flagNew,
        flagVD,
        color,
        gearBox,
        matriculation,
        matriculationDate,
        constructionDate,
        currentMileage,
        chassisReference,
        amountWoTax,
        discountPercentage,
        discountAmount,
        taxAmount,
        assetMargin,
        amountWTax,
        totalAmountWoTax,
        totalTaxAmount,
        totalAmountWTax,
        assetProduct,
        packAssetProduct,
        supplier,
        orderAssetComponents,
        assetDelivery,
        horsePower,
        purchaseAmountWoTax,
        purchaseAmountWTax
    } = orderVehicle
    let supplierConvert: any = {
        party: {
            systemUid: 'odm-party',
        },
        role: {
            objectType: 'odm.order.orderpartyrole',
            systemUid: 'odm-order',
        },
        objectType: 'odm.order.orderparty',
        systemUid: 'odm-order',
    }
    let supplierPartyName = ''
    if (supplier) {
        supplierPartyName = await getSupplierName(orderVehicle)
        supplierConvert = {
            resourceUid: supplier?.resourceUid,
            objectType: 'odm.order.orderparty',
            systemUid: 'odm-order',
            "party": supplier?.party,
            "name": supplierPartyName,
            "role": supplier?.role
        }
    }
    const {options, accessories, others} = convertOrderAssetComponentToAssetItem(orderAssetComponents);
    let assetDeliveryModel = {
        initialDeliveryDate: undefined,
        previsionDeliveryDate: undefined,
        effectiveDeliveryDate: undefined,
        deliveryAddress: undefined,
        description: undefined,
    }
    if (assetDelivery) {
        assetDeliveryModel = deepClone(assetDelivery)
    }
    const result =  {
        quantity: 1,
        description,
        "taxCode": null,
        "taxValue": "20.0",
        discountPercentage,
        amountWoTax,
        discountAmount,
        taxAmount,
        assetMargin,
        amountWTax,
        totalAmountWoTax,
        totalTaxAmount,
        totalAmountWTax,
        purchaseAmountWoTax,
        purchaseAmountWTax,
        assetProduct,
        horsePower,
        packAssetProduct,
        "proposalAssetItems": [...options, ...accessories, ...others],
        "usage": null,
        flagNew,
        flagVD,
        color,
        gearBox,
        chassisReference,
        matriculation,
        matriculationDate,
        constructionDate,
        currentMileage,
        "nbOfCylinder": null,
        "nbOfGear": 0,
        "taxHorsePower": null,
        "co2Emissions": 115,
        "mineType": null,
        "fuelConsumptionUrban": 6.7,
        "fuelConsumptionExtraUrban": null,
        "fuelConsumptionCombined": 0,
        "brand": "",
        "range": "",
        "version": "",
        "carBodyStyle": "",
        "engineType": "",
        "nbOfDoor": null,
        "nbOfSeat": null,
        "objectType": "odm.offer.proposalasset.proposalgood.proposalvehicle.proposalcar",
        "systemUid": "odm-offer",
        "businessData": null,
        "daaq": "/",
        // TODO finish config
        "config": {
            supplierPartyName,
            assetDeliveryModel,
            "proposalAssetCalculated": {
                "product": {
                    "name": "",
                    "unitaryPrice": 0,
                    "discount": 0,
                    "tax": "0.0",
                    "priceWithDiscount": 0,
                    "priceWithTax": 0,
                    "purchaseAmountWoTax": 0,
                    "purchaseAmountWTax": 0,
                    "discountAmount": 0

                }
            },
            "productAssetModel": {
                "label": description,
                "config": {
                    "objectType": "odm.product.productfeature.product.assetproduct.goodproduct.vehicleproduct.carproduct",
                    "resourceUid": "",
                    "systemUid": "odm-product",
                    "businessData": null,
                    "daaq": "/",
                    "reference": null,
                    "name": description,
                    "validity": {
                        "from": "03/07/2023",
                        "until": null
                    },
                    "status": {
                        "objectType": "odm.product.productstatus",
                        "systemUid": "odm-product",
                        "resourceUid": "USE"
                    },
                    "qualifier": null,
                    "tax": {
                        "objectType": "odm.product.producttax",
                        "systemUid": "odm-product",
                        "resourceUid": "FR_TAX"
                    },
                    "feature": [],
                    "classifications": [],
                    "residualValue": {},
                    "images": [],
                    "vehicleLength": null,
                    "nbOfCylinder": 4,
                    "nbOfGear": 5,
                    "taxHorsePower": 5,
                    "horsePower": null,
                    "co2Emissions": 115,
                    "mineType": null,
                    "fuelConsumptionUrban": 6.7,
                    "fuelConsumptionExtraUrban": 4.6,
                    "fuelConsumptionCombined": 5.4,
                    "millage": {},
                    "nbOfDoor": 5,
                    "nbOfSeat": 5,
                    "inventoryItemCars": [],
                    "taxe": {
                        "code": "FR_TAX",
                        "matrix": "",
                        "evaluationMethod": {
                            "id": "FIXED",
                            "label": "FIXED"
                        },
                        "validity": {
                            "from": "2022-06-01",
                            "until": null
                        },
                        "fixedValue": "20.0"
                    }
                }
            },
            "assetType": "vehicle",
            "formBind": {
                supplier: supplierConvert,
                "brand":getFormBind(orderVehicle, 'brand'),
                version: getFormBind(orderVehicle, 'version',
                    (value: any) => value.includes('range.') ? value.split('range.')[1] : value.substring(value.lastIndexOf(".") + 1)),
                range: getFormBind(orderVehicle, 'range'),
                carBodyStyle: getFormBind(orderVehicle, 'carBodyStyle'),
                engineType: getFormBind(orderVehicle, 'engineType'),
            },
            options,
            accessories,
            others,
            "readOnly": false
        },
    }
    const resultTmp = deepClone(result)
    await completeProposalAssetCarInfo(resultTmp as any)
    result.config.proposalAssetCalculated = resultTmp.config.proposalAssetCalculated
    return result
}


const convertAssetItemToOrderAssetComponent = (asset?: any) => {
    return asset.proposalAssetItems.map((item: any) => {
        const {
            type,
            label,
            amountWTax,
            discountPercentage,
            discountAmount,
            taxAmount,
            amountWoTax,
            quantity,
            totalAmountWoTax,
            totalTaxAmount,
            totalAmountWTax,
            purchaseAmountWoTax,
            purchaseAmountWTax,
            config
        } = item
        let orderAssetComponentType
        switch (type) {
            case 'OPTION_CAR':
                orderAssetComponentType = 'OPTION'
                break
            case 'ACCESSORY_CAR':
                orderAssetComponentType = 'ACCESSORY'
                break
            case 'FEES_CAR':
                orderAssetComponentType = 'FEES'
                break
            case 'OTHER_CAR':
                orderAssetComponentType = 'OTHERS'
                break
        }
        return {
            config:config,
            type: orderAssetComponentType,
            label,
            amountWTax,
            discountPercentage,
            discountAmount,
            taxAmount,
            amountWoTax,
            quantity,
            totalAmountWoTax,
            totalTaxAmount,
            totalAmountWTax,
            purchaseAmountWoTax,
            purchaseAmountWTax
        }
    })
}


const convertOrderAssetComponentToAssetItem = (orderAssetComponents?: OrderAssetComponent[]) => {
    const result: any = {
        options: [],
        accessories: [],
        fees: [],
        others: [],
    }
    if (orderAssetComponents) {
        orderAssetComponents.forEach(item => {
            const resultItem: any = item
            resultItem.config = {
                readOnly: true,
                required: false,
                selected: true,
                selectModel: {
                    label: item.label,
                    id: item.label
                },
                unitaryAmountModel: item.amountWoTax?.amount || 0,
                amountWithTaxModel: item.amountWTax?.amount || 0,
                optionMargin: item.optionMargin?.amount || 0,
                taxValueModel: {
                    label: `${formatWithMask(item.taxAmount?.amount || 0, 2)}`,
                    id: item.taxAmount?.amount || 0
                },
                purchaseAmountWTaxModel: item.purchaseAmountWTax?.amount || 0,
                purchaseAmountWoTaxModel: item.purchaseAmountWoTax?.amount || 0,
                reference: ''
            }
            if (item.type) {
                const itemType = item.type?.resourceUid || item.type;
                switch (itemType) {
                    case 'OPTION':
                        resultItem.type = 'OPTION'
                        result.options.push(resultItem)
                        break
                    case 'ACCESSORY':
                        resultItem.type = 'ACCESSORY'
                        result.accessories.push(resultItem)
                        break
                    case 'FEES':
                        resultItem.type = 'FEES'
                        result.fees.push(resultItem)
                        break
                    case 'OTHERS':
                        resultItem.type = 'OTHERS'
                        result.others.push(resultItem)
                        break
                }
            }
        })
    }
    return result
}


const getSupplierName = async (orderAsset: OrderAsset) => {
    let partyName = ''
    if (orderAsset && orderAsset.config) {
        partyName = orderAsset.config.supplierPartyName
    }
    return partyName
}

function getFormBind(proposalAssetCar: any, attr: string, labelFunc?: any) {
    if (proposalAssetCar[attr]) {
        const value = proposalAssetCar[attr]
        return {
            id: value,
            label: labelFunc ? labelFunc(value) : value.substring(value.lastIndexOf(".") + 1),
            config: {}
        }
    } else {
        return {
            id: '',
            label: '',
            config: {}
        }
    }
}



export const getSummaryParties = (order: Order) => {
//     TODO EF-163 - finish
    const orderParties = order.orderParties
    
   /* if(order.orderItems && order.orderItems[0]&& order.orderItems[0].orderAssets&& order.orderItems[0].orderAssets[0]&&order.orderItems[0].orderAssets[0].supplier){
        const Supplier = {businessData: null,
            daaq :"/",
            objectType:"odm.order.orderassociatedparty",
            orderParty:order.orderItems[0].orderAssets[0].supplier,
            resourceUid:"",
            systemUid:"odm-order"
        }
        orderParties?.push(Supplier)
    }*/
    return orderParties
}
export const getOrderAssetServices = (orderAssetServices: any) => {
    const ListOrderAssetServices =[]
    for (const service of orderAssetServices){
        if (service.type && service.type.resourceUid){
            service.type.type={value:service.type.resourceUid,label:service.type.resourceUid}
            ListOrderAssetServices.push(service)
        }
        service.purchase="'"+service.purchase.toString()+"'"
    }
    return ListOrderAssetServices

}

export const convertAssetDeliveryModel = async (orderItems: any,order:any) => {
    const assetDeliveryModelList:any[]=[]
    for(const orderItem of orderItems){
        let assetDeliveryModel = {
            initialDeliveryDate: undefined,
            previsionDeliveryDate: undefined,
            effectiveDeliveryDate: undefined,
            deliveryAddress: undefined,
            description: undefined,
            phoneContact:undefined
        }
        if (orderItem.orderAssets[0].assetDelivery) {
            if(!orderItem.orderItemParent&&!orderItem.orderAssets[0].assetDelivery.process){
                orderItem.orderAssets[0].assetDelivery.process={resourceUid:'ORDER'}
            }
            assetDeliveryModel = deepClone(orderItem.orderAssets[0].assetDelivery)
            if(order.orderParties[0]&& order.orderParties[0].orderParty&&order.orderParties[0].orderParty.resourceUid){
                const orderParty = order.orderParties[0].orderParty
                const party=orderParty.party?.rawData?.responseData
               
                let contactMechanism 
                if(party&& party.contacts){
                    for(const contact of party.contacts){
                        if(contact.contactMechanism.objectType.includes('phonecontact')){
                            contactMechanism=contact.contactMechanism
                        }
                    }
                    if(contactMechanism&&contactMechanism.resourceUid){
                        const phoneContact=contactMechanism
                        assetDeliveryModel.phoneContact=phoneContact.phoneNumber
                    }
                }

                
            }
            

           // const test = await getEntity('odm-party', 'phonecontact', node.contactMechanism.resourceUid)
        }
        assetDeliveryModelList.push(assetDeliveryModel)
    }

    return assetDeliveryModelList
}
export const initOrders = async (orders:any): Promise<any> => {
    const newOrders=[]
    for(const orderItemState of orders){
        if (!orderItemState || !orderItemState.orderAssets?.length) {
            throw new Error('Invalid state initialization')
        }
        const orderAssets =  orderItemState.orderAssets[0]
        const asset= await convertOrderVehicleToProductVehicle(orderAssets)
        orderItemState.orderAssets[0].config=asset.config
        if(orderItemState?.orderAssets[0]&&orderItemState?.orderAssets[0]?.assetProduct){
            const productUid = orderItemState?.orderAssets[0]?.assetProduct.resourceUid
            if (productUid && productUid!==undefined && productUid!==null){ 
                store.dispatch('orderModule/selectGoodImage', {
                    productUid: productUid,
                    callback: (goodImage:any) => {
                        if (goodImage){
                        orderItemState.orderAssets[0].config.selectedGoodImage= goodImage
                        }
                    }
                })
            }
        }

        newOrders.push(orderItemState)
    }
     return   newOrders


}



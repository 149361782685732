import { ContactType, FileContent, Third} from "@/types";
import { Paths, UON, deepClone, unmountSelectFields} from "@/utils";
import { useApi } from "@/requests/useApi";
import { handleOrganisationToSave, handlePersonToSave } from "./order/orderOrch";
import { formatDateLocale } from "@/utils/configuration/formatters-config";


    export function buildRevenues(person: any) {
        for (const revenue of person.revenues) {
            for (const de of revenue.detail) {
                const nature = {
                    resourceUid : de.nature.type
                }
                de.nature = nature
            }  
        }  
        }

    export function buildIds(person: any) {
        for (const id of person.ids) {
            const documentType = {
                type :{
                  value : id?.documentType?.type
                } 
            }
            id.documentType = documentType
        }  
    } 
    export function buildAccounts(accounts: any) {
        for (const account of accounts) {
            if(account.bankAccount){
                const bankAccount= {
                    objectType: "odm.party.bankaccount.ibanbankaccount.frenchbankaccount",
                    systemUid: "odm-party",
                    validity : {
                        from: account.bankAccount.validity.from ? formatDateLocale(account.bankAccount.validity.from, 'DD/MM/YYYY', 'fr') : '',
                        until: account.bankAccount.validity.until ? formatDateLocale(account.bankAccount.validity.until, 'DD/MM/YYYY', 'fr') : '',
                    },
                    bic: account.bankAccount?.bic,
                    iban:  account.bankAccount?.iban.replace(/\s+/g, '')
                }
             account.bankAccount = bankAccount
            }
        }
    }

export function buildCorrespondents(payload: any) {
    for (const corr of payload) {
         const status = {
            objectType: "odm.party.partycorrespondentstatus",
            resourceUid: "ACTIVE",
         }
        corr.status =status
        for (const contact of corr.contactCorrespondent) {
            contact.contactMechanism.objectType =  contact.contactMechanism?.type?.config?.objectType;
        }  
    }  
}
export function buildContacts(payload: any) {
    for (const corr of payload) {
        const objectType = corr.contactMechanism && corr.contactMechanism?.type?.config && corr.contactMechanism?.type?.config?.objectType
                     ? corr.contactMechanism?.type?.config?.objectType : ''
            corr.contactMechanism.objectType =   objectType;
    }  
}


export function buildOrganizationEtablissementAddress(payload: any) {
    let address : any
    for (const etablisment of payload){
        if (etablisment.address) {
            delete etablisment.address?.isDisabled;
             address = deepClone(etablisment.address)
                if (address.postalCode && !address.postalCode.resourceUid && address.postalCode.type.value) {
                    address.postalCode.resourceUid = address.postalCode.type?.value
                }
                if (address.type && !address.type.resourceUid && address.type.type.value) {
                    address.type.resourceUid = address.type.type?.value[0]
                    delete address.type.type
                }
            }
            etablisment.address = address
    }
}
export function buildOrganizationEtablissementAddresses(payload: any) {
    for (const address of payload){
        if (address) {
            address.livingSituation   =  null
            delete address?.isDisabled;
            }
    }
}
export function buildJobLocation(payload: any){
    for (const job of payload){
        if (job.jobLocation) {
                
                const postalCode ={
                    resourceUid : job.jobLocation.postalCode && job.jobLocation.postalCode.postalCode ? job.jobLocation.postalCode.postalCode : '',
                    country : job.jobLocation.postalCode && job.jobLocation.postalCode && job.jobLocation.postalCode.country ? job.jobLocation.postalCode.country : null
                }
                delete job.jobLocation?.postalCode?.type
                delete job.jobLocation?.type
                job.jobLocation.postalCode =postalCode.resourceUid? postalCode : null
            }
    }

}
export const createPartyOrch = async (type: string, payload: any) => {
    return (await useApi(UON.SystemUid.odmOrchestration, type).upsert(payload)).response
}

export function handlePersonToCreate(body: Third) {
    if (body.person){
            if(body.person.ids && body.person.ids.length>0){
                buildIds(body.person)
            }
            if(body.person.revenues && body.person.revenues.length>0){
                buildRevenues(body.person)
            }
            if(body.person.correspondents && body.person.correspondents.length>0){
                buildCorrespondents(body.person.correspondents)
            }
            if(body.person.contacts && body.person.contacts.length>0){
                buildContacts(body.person.contacts)
            }
            if(body.accounts && body.accounts.length>0){
                buildAccounts(body.accounts)
            }
            if(body.person.jobs && body.person.jobs.length>0){
                buildJobLocation(body.person.jobs)
            }

        body.person.addresses =  body.addresses ? body.addresses : []
        body.person.roles =  body.roles ? body.roles : []
        body.person.classifications =  body.classifications ? body.classifications : []
        body.person.financialExposures =  body.financialExposures ? body.financialExposures : []
        body.person.accounts =  body.accounts ? body.accounts : []
    }
    return body.person

}

export async function savePersonOrch(data: Third) {
    const body = data
    const type = Paths.frenchPerson
    if (body.person){
        const person=handlePersonToCreate(deepClone(body))
        const personValue = handlePersonToSave(person)
       //const documents = handleDocuments(personValue.documents,personValue.reference)
        //console.log(documents)
        //personValue.documents=documents
        body.person = await createPartyOrch(type, personValue)
    return body;
    }
    
}

export function handleOrganizationToCreate(body: Third) {
    if (body.organization){
           
            if(body.organization.correspondents && body.organization.correspondents.length>0){
                buildCorrespondents(body.organization.correspondents)
            }
            if(body.organization.contacts && body.organization.contacts.length>0){
                buildContacts(body.organization.contacts)
            }
            if(body.organization.facilities && body.organization.facilities.length>0){
                buildOrganizationEtablissementAddress(body.organization.facilities)
            }
            if(body.addresses && body.addresses.length>0){
                buildOrganizationEtablissementAddresses(body.addresses)
            }
            if(body.accounts && body.accounts.length>0){
                buildAccounts(body.accounts)
            }
        body.organization.addresses =  body.addresses ? body.addresses : []
        body.organization.roles =  body.roles ? body.roles : []
        body.organization.classifications =  body.classifications ? body.classifications : []
        body.organization.financialExposures =  body.financialExposures ? body.financialExposures : []
        body.organization.accounts =  body.accounts ? body.accounts : []
    }
    return body.organization

}
export async function saveOrganizationOrch(data: Third) {
    const body = data
    const type = Paths.frenchOrganization
    if (body.organization){
        const organization = handleOrganizationToCreate(deepClone(body))
        const organizationValue = handleOrganisationToSave(organization)
        //const documents = handleDocuments(organizationValue.documents,organizationValue.reference)
        //organizationValue.documents=documents
        body.organization= await createPartyOrch(type, organizationValue)
    return body;
    }
}

function handleDocuments(documents:any,partyReference:any){
    const listOfdocs=[]
    for(const doc of documents){
        if(doc.resourceUid){
            listOfdocs.push(doc)
        }else{
            console.log(doc)
            const fileContent: FileContent = {
                originalFileName: doc.originalFileName,
                format: doc.format,
                content: doc.content,
                relations: {
                  items: [
                    {
                      qualifier: {
                        rawValue: partyReference,
                      },
                      objectUON: {
                        objectType: "odm.filemngt.filecontent",
                        systemUid: UON.SystemUid.odmFileManagement,
                      },
                    },
                  ],
                },
            }
    
            const document:any={reference: partyReference+doc.originalFileName,
                documentType:null,
                fileReference:{
                    systemUid: "odm-filemngt",
                    objectType:"odm.filemngt.filecontent",
                    rawData:fileContent
                },
                objectType: "odm.party.partydocument",
                systemUid: "odm-party"
            }
            listOfdocs.push(document)
        }
        
    }
    return listOfdocs

}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-9 mb-2" }
const _hoisted_3 = { class: "col-3 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_summaryTabOrderDetails = _resolveComponent("summaryTabOrderDetails")!
  const _component_summaryTabOrderFeatures = _resolveComponent("summaryTabOrderFeatures")!
  const _component_summaryTabOrderClient = _resolveComponent("summaryTabOrderClient")!
  const _component_summaryTabOrderFinancing = _resolveComponent("summaryTabOrderFinancing")!
  const _component_summaryTabOrderVehicle = _resolveComponent("summaryTabOrderVehicle")!
  const _component_summaryTabOrderTradeIn = _resolveComponent("summaryTabOrderTradeIn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_summaryTabOrderDetails, { order: _ctx.order }, null, 8, ["order"]),
    _createVNode(_component_summaryTabOrderFeatures, {
      orderConditions: _ctx.order?.orderConditions?.filter(item=>item.checked)
    }, null, 8, ["orderConditions"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_summaryTabOrderClient, {
          orderClient: _ctx.orderClient,
          asset: _ctx.orderAssetNotTradeIn
        }, null, 8, ["orderClient", "asset"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_summaryTabOrderFinancing)
      ])
    ]),
    _createVNode(_component_summaryTabOrderVehicle, {
      orderItems: _ctx.order?.orderItems
    }, null, 8, ["orderItems"]),
    _createVNode(_component_summaryTabOrderTradeIn, {
      orderItems: _ctx.order?.orderItems,
      orderClient: _ctx.orderClient
    }, null, 8, ["orderItems", "orderClient"])
  ], 64))
}

import {Options, Vue} from "vue-class-component";


@Options({
    props: ["orderClient","asset"],
  components: {
  },
})
export default class summaryTabOrderClient extends Vue {
   orderClient?: any;
   asset?: any;

   get client (){
      return this.orderClient  && this.orderClient.orderParty && this.orderClient.orderParty.party && this.orderClient.orderParty.party.rawData && this.orderClient.orderParty.party.rawData.responseData
                   ? this.orderClient.orderParty.party.rawData.responseData
                   : null
   }
   get clientName() {
    const  clientType  = this.orderClient  && this.orderClient.orderParty && this.orderClient.orderParty.party && this.orderClient.orderParty.party.objectType
                   ? this.orderClient.orderParty.party.objectType
                   : "-"
    return clientType === "odm.party.party.person.frenchperson" 
            ? `${this.client.firstName} ${this.client.familyName}`
            : clientType === "odm.party.party.organization.frenchorganization"
            ?  `${this.client.name}`
            : "-"
  }

  get clientPhone() {
      const contact = this.client && this.client.contacts 
              ? this.client.contacts?.find((contact:any) => contact?.contactMechanism?.type?.id === 'PHONE_CONTACT')
              : null
      return contact && contact.contactMechanism &&  contact.contactMechanism.phoneNumber ? contact.contactMechanism.phoneNumber : "-"
  }
  get clientMail() {
    const contact = this.client && this.client.contacts 
              ? this.client.contacts?.find((contact:any) => contact?.contactMechanism?.type?.id === 'EMAIL_CONTACT') 
              : null;
    return contact && contact.contactMechanism &&  contact.contactMechanism.emailAddress ? contact.contactMechanism.emailAddress : "-"
  }
  get deliveryAdress(){
    return this.asset && this.asset.assetDelivery && this.asset.assetDelivery.description 
            ? this.asset.assetDelivery.description 
            : "-"
  }
}

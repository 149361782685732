
import { Options, Vue } from 'vue-class-component';
  
  @Options({
  props: ["orderConditions"],
})
export default class OrderSummaryConditions extends Vue {
  orderConditions?: Array<any>;

}
  
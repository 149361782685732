import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-2 mb-2" }
const _hoisted_3 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_4 = { class: "font-weight margin-left color" }
const _hoisted_5 = { class: "col-3 mb-2" }
const _hoisted_6 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_7 = { class: "font-weight margin-left color" }
const _hoisted_8 = { class: "col-2 mb-2" }
const _hoisted_9 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_10 = { class: "font-weight margin-left color" }
const _hoisted_11 = { class: "col-3 mb-2" }
const _hoisted_12 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_13 = { class: "font-weight margin-left color" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-2 mb-2" }
const _hoisted_16 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_17 = { class: "font-weight margin-left color" }
const _hoisted_18 = { class: "col-3 mb-2" }
const _hoisted_19 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_20 = { class: "font-weight margin-left color" }
const _hoisted_21 = { class: "col-2 mb-2" }
const _hoisted_22 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_23 = { class: "font-weight margin-left color" }
const _hoisted_24 = { class: "col-3 mb-2" }
const _hoisted_25 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_26 = { class: "font-weight margin-left color" }
const _hoisted_27 = { class: "col-2 mb-2" }
const _hoisted_28 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_29 = { class: "font-weight margin-left color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("demand.tabs.summary.details.externalReference")), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.externalReferance), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("demand.tabs.summary.details.clientreference")), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.clientReference), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("demand.tabs.summary.details.process")), 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.internalCode), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("demand.tabs.summary.details.status")), 1),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.orderStatus), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("demand.tabs.summary.details.creationDate")), 1),
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.effectiveDeliveryDate), 1)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("demand.tabs.summary.details.initialDate")), 1),
        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.initialDeliveryDate), 1)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("demand.tabs.summary.details.engagementDate")), 1),
        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.engagementDate), 1)
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("demand.tabs.summary.details.previsionalDate")), 1),
        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.previsionDeliveryDate), 1)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("demand.tabs.summary.details.appointmentDate")), 1),
        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.appointementDate), 1)
      ])
    ])
  ], 64))
}
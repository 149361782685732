import { UON, headers, operator, setOtherFilters } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import { QcHeader } from "@/types";

const odm_user = UON.SystemUid.odmUser; 
const odmConfiguration = UON.SystemUid.odmConfiguration;
export async function fetchRoleMenus() {
  return (
    await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify({
          queryId: "role-menu-authority",
          offset: 0,
          limit: -1,
        }),
      }),
    })
  ).data;
}

export async function fetchSecurityGroups() {
  return (
    await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify({
          queryId: "get-security-groups",
          offset: 0,
          limit: -1,
        }),
      }),
    })
  ).data;
}

export async function fetchGroupsAuthoritiesByCode(authority_code: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-group-authority-by-code",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: authority_code,
      table: "user_group_authority",
      dbField: "authority_code",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

export async function addGroupAuthority(
  authority_code: string,
  group_id: string
) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/securitygroup/${group_id}/groupauthority/`;
  const result = await api().post(`${baseUrl}`, {
    objectType: "odm.user.groupauthority",
    systemUid: odm_user,
    authority: {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: authority_code,
    },
  });

  return result;
}

export async function deleteGroupAuthority(id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/groupauthority/${id}/`;
  const result = await api().delete(`${baseUrl}`);
  return result;
}

export async function fetchUserAuthoritiesByCode(authority_code: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-user-authority-by-code",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: authority_code,
      table: "user_user_authority",
      dbField: "authority_code",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

export async function addUserAuthority(
  authority_code: string,
  user_id: string
) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/leaseforgeuser/${user_id}/userauthority/`;
  const result = await api().post(`${baseUrl}`, {
    objectType: "odm.user.userauthority",
    systemUid: odm_user,
    authority: {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: authority_code,
    },
  });

  return result;
}

export async function deleteUserAuthority(id: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/userauthority/${id}/`;
  const result = await api().delete(`${baseUrl}`);
  return result;
}

export async function saveNewRoleName(roleName: string) {
  const baseUrl = `${settings.api_url}/${odm_user}/api/1/${odm_user}/authority/`;
  const result = (
    await api().post(`${baseUrl}`, {
      objectType: "odm.user.authority",
      systemUid: odm_user,
      resourceUid: "ROLE_MENU_" + roleName,
    })
  ).data?.resourceUid;
  return result;
}

export async function uploadTranslation(lang: string , key: any , value: any){
  const baseUrl = `${settings.api_url}/${odmConfiguration}/api/1/${odmConfiguration}/translation/upload/`;
  const result = await api().post(`${baseUrl}`, {
    locale: `${lang}-Us`,
    content: {
      [`main.menu.${key}`]: value
    },
  })
  return result;
}
export async function fetchTranslationsBykey(key: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "translation-value-by-key",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];

  operators.push(
    operator({
      val: `main.menu.${key}`,
      table: "configuration_translation",
      dbField: "key",
    }).LIKE
  );

  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}



import store from "@/store";
import {Options, Vue} from "vue-class-component";


@Options({
  components: {
  },
})
export default class summaryTabOrderFinancing extends Vue {



  get orderFinancing() {
    return store.getters["orderModule/orderFinancingModule/getOrderFinancing"];
  }
}


import i18n from "@/i18n";
import {Options, Vue} from "vue-class-component";


@Options({
  props: ["order"],
  components: {
  },
})
export default class summaryTabOrderDetails extends Vue {
 order?: any;
   get orderStatus() {
    return this.order && this.order.status && this.order.status.resourceUid 
           ? i18n.global.t("order.picklist.status." + this.order.status?.resourceUid) 
           : "-";
  }
  get effectiveDeliveryDate() {
      return this.order && this.order.effectiveDeliveryDate 
            ? this.order.effectiveDeliveryDate 
            : "-";
  }
  get initialDeliveryDate(){
    return this.order && this.order.orderItems && this.order.orderItems[0] && this.order.orderItems[0].initialDeliveryDate 
            ? this.order.orderItems[0].initialDeliveryDate
            : "-";
  }
    get previsionDeliveryDate(){
    return this.order && this.order.orderItems && this.order.orderItems[0] && this.order.orderItems[0].previsionDeliveryDate 
            ? this.order.orderItems[0].previsionDeliveryDate
            : "-";
  }
   get engagementDate(){
    return "-";
  }
   get appointementDate(){
    return "-";
  }
  get externalReferance(){
    return this.order && this.order.orderItems && this.order.orderItems[0] && this.order.orderItems[0].externalReference 
            ? this.order.orderItems[0].externalReference
            : "-";
  }
    get internalCode() {
      return this.order && this.order.status && this.order.status.internalCode 
            ?  this.order.status && this.order.status.internalCode 
            : "-";
  }
   get clientReference() {
    const client = this.order && this.order.orderParties && this.order.orderParties.length
                   ? this.order.orderParties.filter((item:any) => item.orderParty && item.orderParty.role  && item.orderParty.role.resourceUid&& item.orderParty.role.resourceUid==="CUSTOMER")[0]
                   : null
      return client  && client.orderParty && client.orderParty.party && client.orderParty.party.rawData && client.orderParty.party.rawData.responseData  && client.orderParty.party.rawData.responseData.reference
             ? client.orderParty.party.rawData.responseData.reference
             : "-"
           
  }
}
 


import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row mt-4 mb-2 font-weight-bold",
  style: {"font-size":"16px","text-decoration":"underline"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_5 = { class: "font-weight margin-left color" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "d-block font-semi-bold mb-0 font-size color font-weight" }
const _hoisted_9 = { class: "font-weight margin-left color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('demand.tabs.summary.finacing.label')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('demand.tabs.summary.finacing.refContract')), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.orderFinancing.offerReference ? _ctx.orderFinancing.offerReference : "-"), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('demand.tabs.summary.finacing.scale')), 1),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.orderFinancing.label ? _ctx.orderFinancing.label : "-"), 1)
      ])
    ])
  ], 64))
}

import {Options, Vue} from "vue-class-component";
import summaryTabOrderDetails from "@/components/summary-panel/order/summaryTab/summaryTabOrderDetails.vue";
import summaryTabOrderFeatures from "@/components/summary-panel/order/summaryTab/summaryTabOrderFeatures.vue";
import summaryTabOrderClient from "@/components/summary-panel/order/summaryTab/summaryTabOrderClient.vue";
import summaryTabOrderFinancing from "@/components/summary-panel/order/summaryTab/summaryTabOrderFinancing.vue";
import summaryTabOrderVehicle from "@/components/summary-panel/order/summaryTab/summaryTabOrderVehicle.vue";
import summaryTabOrderTradeIn from "@/components/summary-panel/order/summaryTab/summaryTabOrderTradeIn.vue";


@Options({
  props: ["order"],
  components: {
    summaryTabOrderDetails,
    summaryTabOrderFeatures,
    summaryTabOrderClient,
    summaryTabOrderFinancing,
    summaryTabOrderVehicle,
    summaryTabOrderTradeIn
  },
})
export default class OrderSummary extends Vue {
  order?: any;
   get orderClient() {
    const client = this.order && this.order.orderParties && this.order.orderParties.length
                   ? this.order.orderParties.filter((item:any) => item.orderParty && item.orderParty.role  && item.orderParty.role.resourceUid&& item.orderParty.role.resourceUid==="CUSTOMER")[0]
                   : null
      return client      
  }
   get orderAssetNotTradeIn() {
    return this.order && this.order.orderItems && this.order.orderItems[0] && this.order.orderItems[0].orderAssets && this.order.orderItems[0].orderAssets.length
            ? this.order.orderItems[0].orderAssets.filter((asset:any)=> asset.assetTradeIn === null)[0]
            : null;
  }  
}

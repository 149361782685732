import { ComboBox, Picklist, PicklistItem, PicklistState, QcHeader, systemUidOdmContactmechanism } from "@/types";
import { settings } from "@/settings";
import { globalConfig, headers, UON, useDate } from "@/utils";
import axios from "axios";
import i18n from "../../i18n";
import { useApi } from "@/requests/useApi";
import { getSubjectsGeneric } from "@/store/services/communication/messaging";
import { api } from "@/auth/api";
import store from "..";

const api_query_url = settings.api_query_url;

const odm_product = "odm-product";
const odm_simulation = "odm-simulation";
const odm_offer = "odm-offer";
const odm_party = "odm-party";
const odm_asset = "odm-asset";
const odm_decision = "odm-decision";
const odm_workflow = "odm-workflow";
const odm_order ="odm-order";
const odm_configuration = "odm-configuration";
const odm_user = "odm-user";

export function getPicklistInitialValues(): Promise<PicklistState> {
  return new Promise<PicklistState>(async (resolve, reject) => {
    const state: PicklistState = {};
    try {
      state.typeService = await getTypeService();
      state.baseCalcule = await getBaseCalcule();
      state.term = await getTerm();
      state.paymentDate = await getPaymentDate();
      state.paymentFrequence = await getPaymentFrequence();
      state.demandStatus = await getDemandStatus();
      state.clientType = await getClientType();
      state.current_status_code = await getClientStatus();
      state.brand = await getBrand();
      state.range = await getRange();
      state.role = await getPartyRules();
      state.activitySector = await getActivitySector();
      state.legalCategory = await getLegalCategory();
      state.relations = await getRelations();
      state.relationType = await getRelationType();
      state.countries = await getCountries();
      state.geographicZone = await getGeographicZone();
      state.postalCodes = await getPostalCodes();
      state.statusMarital = await getStatusMarital();
      state.matrimonialRegime = await getMatrimonialRegime();
      state.addressType = await getAddressType();
      state.addressPreferenceType = await getAddressPreferenceType();
      state.titles = await getTitles();
      state.occupation = await getOccupation();
      state.periodRateOption = await getPeriodRateOption();
      state.warrantMethod = await getWarrantMethod();
      state.contactType = await getContactType();
      state.IdProof = await getIdProof();
      state.facilityType = await getFacilityType();
      state.accountType = await getAccountType();
      state.accountStatus = await getAccountStatus();
      state.nationality = await getNationality([]);
      state.jobActivity = await getJobActivity();
      state.jobPositionType = await getJobPositionType();
      state.jobOccupationType = await getJobOccupationType([]);
      state.nature = await getNature();
      state.periodicity = await getPeriodicity();
      state.partyRelationType = await getPartyRelationTypes();
      state.language = await getLanguage();
      //Asset
      state.categoryAsset = await getCategoriesAsset();
      state.usageAsset = await getUsageAsset();
      state.colors = await getColorsAsset();
      state.statusOrder = await getStatusOrderList();
     
      state.activity = await getActivityAsset();
      state.bodyAsset = await getBodiesAsset();
      state.brandAsset = await getBrandsAsset();
      state.modelAsset = await getModelsAsset();
      state.finishingAsset = await getFinishesAsset();
      state.fuelTypeAsset = await getFuelTypesAsset();
      state.emissionCO2Asset = await getEmissionsCO2Asset();
      state.powerHPAsset = await getPowersHPAsset();
      state.qualificationType = await getQualificationType();
      state.assets = await getAssetsMock();
      state.typeCredits = await getTypeCredits();
      state.organization = await getOrganization();
      state.objects = await getObjects();
      //party
      state.natureBalanceSheet = await getNatureBalanceSheet();
      state.roleStatus = await getRoleStatus();
      state.partyClass = await getPartyClass();
      state.partyClassificationType = await getPartyClassificationType();
      state.signatureMethods = await getSignatureMethods();
      //Network
      state.channel = await getChannel();
      //Task
      state.actionType = await getActionType();
      state.module = await getModule();
      //Decision
      state.decisionStatus = await getDecisionStatus();
      state.decisionMotif = await getDecisionMotif();
      state.decisionreserve = await getDecisionReserve();
      //Decision
      state.decisionOpinion = await getDecisionOpinion();
      //Communication
      state.messageSubjects = await getSubjectsGeneric();

      state.financialProfile = await getScaleFinancialProfile();
      state.scaleStatus = await getScaleStatus();
      state.scaleCategory = await getScaleCategory();
      state.productTax = await getProductTax();
      state.scalefrequency = await getScaleProductFrequency();
      state.scaleRateBasis = await getScaleRateBasis();
      state.scaleGracePeriodCalculationMethod = await getScaleGracePeriodCalculationMethod();
      state.serviceType = await getServiceType();
      state.productBasis = await getProductBasis();
      state.accessoryCalculationMethod = await getAccessoryCalculationMethod();
      state.productTaxServices = await getProductTaxServices();
      state.accessoryPaymentDatesOption = await getAccessoryPaymentDatesOption();

      state.assetTypeItems = await getAssetType();
      state.assetStatut = await getAssetStatut();
      state.assetCatalog = await getAssetCatalog();
      state.garantyStatus = await getGarantyStatus();
      state.garantyType = await getGarantyType();
      state.typeOutstanding = await getTypeOutstanding();
      state.financialInstitution = await getFinancialInstitution();
      state.underlyingType = await getUnderlyingType();
      state.garantyBasis = await getGarantyBasis();
      state.garantyrole = await getGarantyRules();
      state.gender = await getGender();
      state.contractType = await getcontractType();
      state.propertyStatus = await getpropertyStatus();
      state.documentType = await getdocumentType();
      state.productAvailabilityContext = await getProductAvailabilityContext();
      state.codeNaceOptions = await getcodeNaceOptions();
      //order
      state.orderStatus = await getOrderStatus();
      state.manualTask = await getManualTask()
      state.orderItemStatus = await getOrderItemStatus();
      state.orderTypeFinancing = await getOrderTypeFinancing();
      state.orderServiceType = await getOrderServiceType();

      state.delegationLevels = await getDelegationLevels();
      state.roleOrder = await getroleOrder();
      state.subventionOptions = await getSubventionOptions();
      state.subventionTypesOptions = await getSubventionTypesOptions();
      //User
      state.userSecurityGroup = await getUserSecurityGroup();
      state.userTeam = await getUserTeam();

      state.userNetwork = await getuserNetwork();
      state.userNetworkNode = await getuserNetworkNode();

      //uploadDocumentsConditions
      state.justifyTypes = await getJustifyTypes();
      state.supportingDocumentType = await getSupportingDocumentType();
      state.usersSchedule =await getUsersSchedule();

      // objectives 
      state.payPlanFrequency =await getPayPlanFrequency();
      state.payPlanActivity =await getPayPlanActivity();
      state.payPlanStatus =await getPayPlanStatus();
      state.payPlanBasis = await getBasis();
      state.payPlanDetailsBasis = await getDetailsBasis();
      state.payPlanSellers = await getVendorsList();
      state.payPlanObjectiveList=await getPayPlanObjective();


      resolve(state);
    } catch (e) {
      reject(e);
    }
  });
}

export function getWarrantMethod(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "payed",
        label: "Rémunéré",
      },
      {
        id: "nonPayed",
        label: "Non rémunéré",
      },
    ],
  });
}

export function getTerm(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "PayAdvanceIntAdvance",
        label: "In advance",
      },
      {
        id: "PayAdvanceIntArrears",
        label: "Mixed",
      },
      {
        id: "PayAdvanceIntMoved",
        label: "Interests moved",
      },
      {
        id: "PayArrearsIntArrears",
        label: "In arrears",
      },
    ],
  });
}

export function getPaymentDate(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "FixedDate",
        label: "Fixe date",
      },
    ],
  });
}

export function getPaymentFrequence(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "mois",
        label: "Mois",
      },
    ],
  });
}

export function getBaseCalcule(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "FinancedAmount",
        label: "Financed Amount",
      },
    ],
  });
}

export function getPeriodRateOption(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "NominalPeriodRateMethod",
        label: "Nominal rate",
      },
    ],
  });
}

export function getDemandStatus(): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "offer-picklist-status",
        offset: 0,
        limit: 500,
      },
    };
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_offer,
          entityName: 'offerstatus',
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: i18n.global.t(`enum.offer.status.${resItem.id}`),
              config: {
                internalCode : resItem.internal_code,
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) =>
        resolve({
          systemUid: odm_simulation,
          items: [
            { id: "INITIAL", label: "Initiale" },
            { id: "CONTRACT", label: "Edition Contrat" },
            { id: "CANCELLED", label: "Annulé" },
            { id: "CUSTACCEPT", label: "Acceptation Client" },
            { id: "ANALYSIS", label: "Analyse en cours" },
            { id: "DOCCOMPLETE", label: "Document Completé" },
            { id: "DOCVALID", label: "Document valide" },
            { id: "DOCNOTVALID", label: "Document non valide" },
            { id: "ACCEPTED", label: "Decision Acceptée" },
            { id: "REFUSED", label: "Decision Refusée" },
            { id: "DOCGEN", label: "Contract generée" },
            { id: "CONTRACTSIGN", label: "Contract Signé" },
          ],
        })
      );
  });
}

export function addEmptyValue(value: any): PicklistItem {
  return {
    id: undefined,
    label: "",
  };
}

export function getBrand(): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const brand = "Brand";
    const qc_header: any = {
      qc: {
        queryId: "product-asset-category",
      },
    };

    qc_header.qc.parameters = {
      brand,
    };
    const operators = [];

    operators.push(
      getOperator(
        "product_product_category",
        "label",
        "LIKE",
        globalConfig.formatters.getLikeValue(brand),
        "label"
      )
    );

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 50;
    }
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        
        const response = {
          systemUid: odm_product,
          entityName: 'productcategory',

          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: resItem.id.replace(/asset.good.vehicle.brand./g, ""),
            };
          }),
        };
        resolve(response);
      })
      .catch((err) =>
        resolve({
          systemUid: odm_simulation,
          items: [
            { id: "asset.good.vehicle.brand.AUDI", label: "AUDI" },
            { id: "asset.good.vehicle.brand.GOLF", label: "GOLF" },
            { id: "asset.good.vehicle.brand.HUYNDAI", label: "HUYNDAI" },
          ],
        })
      );
  });
}


export function getRange(): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "product-asset-category",
      },
    };


    const operators = [];

    operators.push(
      getOperator(
        "product_product_category_class",
        "category_id",
        "LIKE",
        globalConfig.formatters.getLikeValue("asset.good.vehicle.brand.range"), "category_id"
      )
    );

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 50;
    }
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_product,
          entityName: 'productcategory',
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: afterLast(resItem.id, '.')
            };
          }),
        };
        resolve(response);
      })
      .catch((err) =>
        resolve({
          systemUid: odm_simulation,
          items: [
            { id: "asset.good.vehicle.brand.BMW.range.X6", label: "X6" },
            { id: "asset.good.vehicle.brand.HUYNDAI.range.GRAND i10", label: "GRAND i10" },
            { id: "asset.good.vehicle.brand.HUYNDAI.range.i10", label: "i10" },
          ],
        })
      );
  });
}
function afterLast(value: any, delimiter: any) {
  value = value || ''

  return delimiter === ''
    ? value
    : value.split(delimiter).pop()
}
export function getOperator(
  table: string,
  field: string,
  operator: string,
  value?: any,
  parameter?: string
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
    },
  };
}

export function getInOperator(
  table: string,
  field: string,
  operator: string,
  value: any,
  parameter: any,
  inParameters: string
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
      inValue: {
        values: [inParameters],
      },
    },
  };
}

export function getChannel(): Promise<Picklist> {
  const qc_header: any = {
    qc: {
      queryId: "offer-sales-channel",
    },
  };
  return new Promise<Picklist>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_offer,
          entityName: 'saleschannel',
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => {
        //FIXME: until debizium is fixed
        //reject(err)

        resolve({
          systemUid: odm_offer,
          items: [
            {
              id: "Presse",
              label: "Presse",
            },
            {
              id: "Telephone",
              label: "Telephone",
            },
            {
              id: "Mail",
              label: "Mail",
            },
            {
              id: "Web",
              label: "Web",
            },
          ],
        });
      });
  });
}

export function getRoleStatus(): Promise<ComboBox> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        value: "NEW",
        label: "NEW",
      },
      {
        value: "ACTIVATED",
        label: "ACTIVATED",
      },
      {
        value: "CLOSED",
        label: "CLOSED",
      },
    ],
  });
}

export function getPartyClass(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-class-picklist",
    },
  };

  return new Promise<ComboBox>(
    callDataService(qc_header, odm_party, "partyclass"),
  );
}

export function getClientType(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_party,
    items: [
      {
        id: '',
        label: ''
      },
      {
        id: "party-FrenchPerson",
        label: i18n.global.t('enum.party.type.party-Person'),
      },
      {
        id: "party-FrenchOrganization",
        label: i18n.global.t('enum.party.type.party-Organization'),
      },
    ],
  });
}

export function getClientStatus(): Promise<Picklist> {
  return new Promise<Picklist>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "party-client-status",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: 'status',
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: resItem.id,
              config: {
                internalCode : resItem.internal_code,
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getPartyRules(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "party-roles-code",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: 'partyrole',
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t('demand.party.roles.'.concat(resItem.id)).toUpperCase(),
              config: {
                internalCode : resItem.internal_code,
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTypeService(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "assurance",
        label: "Assurance",
      },
      {
        id: "contract",
        label: "Contract",
      },
    ],
  });
}

export function getActivitySector(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "party-code-ape",
            offset: 0,
            limit: -1,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.map((el: { apecode: string }) => {
            return {
              value: el.apecode,
              label: el.apecode,
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });




}

export function getcodeNaceOptions(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "party-code-nace",
            offset: 0,
            limit: -1,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.map((el: { nacecode: string }) => {
            return {
              value: el.nacecode,
              label: el.nacecode,
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getRelations(): Promise<ComboBox> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        value: "ascending",
        label: "ascending",
      },
      {
        value: "descending",
        label: "descending",
      },
    ],
  });
}

export async function getRelationType(): Promise<ComboBox> {
  const qc_header = {
    queryId: "picklist-party-party-relation-type",
    offset: 0,
    limit: 150,

  };
  const _headers = headers({ qc: JSON.stringify(qc_header) });
  const { data } = await axios.get(api_query_url, { headers: _headers });
  return {
    systemUid: odm_party,
    entityName: 'relationtype',
    items: data.map((el: any) => {
      return {
        value: el.id,
        label: el.id,
        ascendinglabel:el.ascendinglabel,
        descendinglabel: el.descendinglabel
      }
    })
  }
}

export function getCountries(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "FRA",
        label: "France",
      },
      {
        id: "MAR",
        label: "Marroco",
      },
    ],
  });
}
export function getGeographicZone(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "party-geographical-zone",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: `${i18n.global.t('demand.party.geographicZone.'.concat(resItem.id))}`,
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });



}

export function getPostalCodes(
  country_code = "FRA",
  query = "%"
): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-postalcode",
      offset: 0,
      limit: 150,
      parameters: { country_code, query },
    },
  };


  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.sort(
            (a: any, b: any) =>
              a.postal_code - b.postal_code
          ).map((resItem: any) => {
            return {
              value: resItem.id,
              label: `${resItem.postal_code} - ${resItem.city}`,
              config: {
                postal_code: resItem.postal_code,
                city: resItem.city,
                department: `${resItem.department}`,
                country: {
                  id: country_code,
                  label: resItem.name,
                },
              },
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getAssetsMock(query = "%"): Promise<Picklist> {
  const toSearch = query.replaceAll("%", "");
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "HYUNDAY I10 - 1.2 L GL",
        label: "HYUNDAY I10 - 1.2 L GL",
        config: {
          product: {
            code: "automotive.5000",
            name: "HYUNDAY I10",
            price: 40000.0,
            currency: "Euro",
            tax: 1,
            discount: 0,
            taxCode: "1",
            powerPhp: {
              id: "1",
              label: "80HP",
            },
            fuelConsumptionExtraUrban: "1",
            nbOfDoor: "4",
            nbOfGear: "1",
            nbOfCylinder: "1",
            fuelConsumptionUrban: {
              id: "1",
              label: "eletric",
            },
            fuelConsumptionCombined: "1",
            taxHorsePower: "1",
            mineType: {
              id: "1",
              label: "SUV",
            },
            co2emissions: {
              id: "1",
              label: "120g/Km",
            },
            nbOfSeat: "1",
            category: {
              id: "NATURE.car",
              label: "Car",
            },
            brand: {
              id: "3",
              label: "Hyundai",
            },
            model: {
              id: "1",
              label: "1.2",
            },
            finishing: {
              id: "1",
              label: "Farmer",
            },
          },
          classification: "1",
          categoryClass: "1",
        },
      },
      {
        id: "BMW S3 - 2.0",
        label: "BMW S3 - 2.0",
        config: {
          product: {
            code: "automotive.5001",
            name: "BMW S3",
            price: 60000.0,
            currency: "Euro",
            discount: 0,
            tax: 1,
            taxCode: "1",
            powerPhp: {
              id: "1",
              label: "100HP",
            },
            fuelConsumptionExtraUrban: "1",
            nbOfDoor: "4",
            nbOfGear: "1",
            nbOfCylinder: "1",
            fuelConsumptionUrban: {
              id: "1",
              label: "eletric",
            },
            fuelConsumptionCombined: "1",
            taxHorsePower: "1",
            mineType: {
              id: "1",
              label: "SUV",
            },
            co2emissions: {
              id: "1",
              label: "140g/Km",
            },
            nbOfSeat: "1",
            category: {
              id: "NATURE.car",
              label: "Car",
            },
            brand: {
              id: "4",
              label: "BMW",
            },
            model: {
              id: "1",
              label: "2.0",
            },
            finishing: {
              id: "1",
              label: "Farmer",
            },
          },
          classification: "1",
          categoryClass: "1",
        },
      },
      {
        id: "CH-R - 1.8 HYBRID",
        label: "CH-R - 1.8 HYBRID",
        config: {
          product: {
            code: "automotive.100001",
            name: "CH-R",
            price: 23000.0,
            currency: "Euro",
            discount: 0,
            tax: 1,
            taxCode: "1",
            powerPhp: {
              id: "1",
              label: "80HP",
            },
            fuelConsumptionExtraUrban: "1",
            nbOfDoor: "4",
            nbOfGear: "1",
            nbOfCylinder: "1",
            fuelConsumptionUrban: {
              id: "1",
              label: "Hybrid",
            },
            fuelConsumptionCombined: "1",
            taxHorsePower: "1",
            mineType: {
              id: "1",
              label: "SUV",
            },
            co2emissions: {
              id: "1",
              label: "120g/Km",
            },
            nbOfSeat: "1",
            category: {
              id: "NATURE.car",
              label: "Car",
            },
            brand: {
              id: "5",
              label: "Toytota",
            },
            model: {
              id: "1",
              label: "1.8",
            },
            finishing: {
              id: "1",
              label: "Farmer",
            },
          },
          classification: "1",
          categoryClass: "1",
        },
      },
      {
        id: "BMW X5 - 2.4",
        label: "BMW X5 - 2.4",
        config: {
          product: {
            code: "automotive.100002",
            name: "BMW X5",
            price: 90000.0,
            currency: "Euro",
            discount: 0,
            tax: 1,
            taxCode: "1",
            powerPhp: {
              id: "1",
              label: "140HP",
            },
            fuelConsumptionExtraUrban: "1",
            nbOfDoor: "4",
            nbOfGear: "1",
            nbOfCylinder: "1",
            fuelConsumptionUrban: {
              id: "1",
              label: "eletric",
            },
            fuelConsumptionCombined: "1",
            taxHorsePower: "1",
            mineType: {
              id: "1",
              label: "SUV",
            },
            co2emissions: {
              id: "1",
              label: "150g/Km",
            },
            nbOfSeat: "1",
            category: {
              id: "NATURE.car",
              label: "Car",
            },
            brand: {
              id: "4",
              label: "BMW",
            },
            model: {
              id: "1",
              label: "2.4",
            },
            finishing: {
              id: "1",
              label: "Farmer",
            },
          },
          classification: "1",
          categoryClass: "1",
        },
      },
    ].filter((item) => item.label.includes(toSearch)),
  });
}
export function getAssets(query = "%"): Promise<Picklist> {
  const qc_header: any = {
    qc: {
      queryId: "offer-asset-search",
      offset: 0,
      limit: 150,
      parameters: { query },
    },
  };
  return new Promise<Picklist>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_product,
          entityName: "proposalasset",
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.product_code,
              label: resItem.product_name,
              category: resItem.product_category_code,
              classification: resItem.product_classification_code,
              categoryClass: resItem.product_category_class_code,
              config: {
                product: {
                  code: resItem.product_code,
                  name: resItem.product_name,
                  powerPhp: {
                    id: resItem.product_tax_horse_power,
                    label: resItem.product_tax_horse_power,
                  },
                  fuelConsumptionExtraUrban:
                    resItem.product_fuel_consumption_extra_urban,
                  nbOfDoor: resItem.product_nb_of_door,
                  nbOfGear: resItem.product_nb_of_gear,
                  nbOfCylinder: resItem.product_nb_of_cylinder,
                  fuelConsumptionUrban: {
                    id: resItem.product_fuel_consumption_urban,
                    label: resItem.product_fuel_consumption_urban,
                  },
                  fuelConsumptionCombined:
                    resItem.product_fuel_consumption_combined,
                  taxHorsePower: resItem.product_tax_horse_power,
                  mineType: resItem.product_mine_type,
                  co2emissions: resItem.product_co_2emissions,
                  nbOfSeat: resItem.product_nb_of_seat,
                  category: {
                    id: "NATURE.car",
                    label: "Car",
                  },
                  price: resItem.product_price_amount,
                  currency: resItem.product_price_currency,
                  tax: resItem.product_tax_fixed_value,
                  taxCode: resItem.product_tax_code,
                  discount: 0,
                  brand: "",
                  model: "",
                  finishing: "",
                },
              },
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getStatusMarital(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-marital-status",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "maritalstatus",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getMatrimonialRegime(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-matrimonial-regime",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "matrimonialregime",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t(
                "demand.party.regimeMatrimonial.".concat(resItem.id)
              ),
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getNationality(filter: any): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-country",
      offset: 0,
      limit: 50,
    },
  };
  const operators = []
  if (filter) {
    operators.push(getOperator("lower(party_country", "name)", "LIKE", globalConfig.formatters.getEqualValue(filter + '%').toLowerCase(), ""))
  }
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
    qc_header.qc.otherOrders = null
  }

  const _headers = headers({ qc: JSON.stringify(qc_header.qc) });
  return new Promise<ComboBox>((resolve, reject) => {
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.name
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });

}

const callDataService = (qc_header: any, systemUid: string, from: string) => {
  return (resolve: any, reject: any) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: systemUid,
          entityName: from,
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  };
};

export function getJobActivity(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-activity",
    },
  };
  return new Promise<ComboBox>(
    callDataService(qc_header, odm_party, "activity")
  );
}

export function getJobPositionType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-position-type",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "positiontype",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getNature(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-expense-income-type",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "expenseincome",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
              config: {
                internalCode: resItem.internal_code,
                defaultValue: resItem.default_value,
                businessData: resItem._business_data,
              },
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getPeriodicity(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-expense-income-periodicity",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "expenseincomeperiodicity",
          items: res.data.map((resItem: any) => {
            return {
              label: resItem.label,
              value: resItem.id,
              config: {
                internalCode : resItem.internal_code,
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getJobOccupationType(filter: any): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-occupation-type",
      offset: 0,
      limit: 150,
    },
  };

  const operators = []
  if (filter) {
    operators.push(getOperator("lower(party_occupation_type", "id)", "LIKE", globalConfig.formatters.getEqualValue(filter + '%').toLowerCase(), ""))
  }
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
    qc_header.qc.otherOrders = null
    qc_header.qc.offset = 0
    qc_header.qc.limit = 50
  }

  const _headers = headers({ qc: JSON.stringify(qc_header.qc) });
  return new Promise<ComboBox>((resolve, reject) => {
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "occupationtype",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });

}

export function getNamesServices(parentId: string): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "fraisDossier",
        label: "Frais de dossier",
        parent: "contract",
      },
      {
        id: "pneumatique",
        label: "Pneumatique",
        parent: "assurance",
      },
      {
        id: "xxx",
        label: "XXXX",
        parent: "assurance",
      },
    ].filter((item) => item.parent === parentId),
  });
}

export function getAddressPreferenceType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-person-preference-address-type",
      offset: 0,
      limit: 150,
    },
  };

  const operators = []

  operators.push(getOperator("party_preference_type", "internal_code", "EQUAL", globalConfig.formatters.getEqualValue("ADDRESS"), ""))

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
    qc_header.qc.otherOrders = null
    qc_header.qc.offset = 0
    qc_header.qc.limit = 50
  }

  const _headers = headers({ qc: JSON.stringify(qc_header.qc) });
  return new Promise<ComboBox>((resolve, reject) => {
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "partyaddresspreference",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
              config: {
                internalCode : resItem.internal_code,
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getAddressType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-person-address-type",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "addresstype",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t(
                "demand.party.addressType.".concat(resItem.id)
              ),
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getTitles(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-person-title-generic",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          entityName: 'persontitle',
          systemUid: odm_party,
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t("demand.party.title.".concat(resItem.id)),
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getOccupation(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "1",
        label: "Farmer",
      },
      {
        id: "2",
        label: "Doctor",
      },
    ],
  });
}

export function getContactType(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_party,
    items: [
      {
        id: "EMAIL_CONTACT",
        label: "EMAIL_CONTACT",
        config: {
          objectType: "odm.party.contactmechanism.emailcontact",
          fields: [
            "emailAddress",
            "objectType",
            "systemUid",
            "resourceUid",
            "validity",
            "nonSolicitation",
            "daaq" /*"consent", "facility"*/,
          ],
        },
      },
      {
        id: "MESSENGER_CONTACT",
        label: "MESSENGER_CONTACT",
        config: {
          objectType: "odm.party.contactmechanism.messengercontact",
          fields: [
            /*"client",*/ "identifier",
            "objectType",
            "systemUid",
            "resourceUid",
            "validity",
            "nonSolicitation",
            "daaq" /*"consent", "facility"*/,
          ],
        },
      },
      {
        id: "PHONE_CONTACT",
        label: "PHONE_CONTACT",
        config: {
          objectType: "odm.party.contactmechanism.phonecontact",
          fields: [
            "phoneNumber",
            /*"country",*/ "acceptSMS",
            "objectType",
            "systemUid",
            "resourceUid",
            "validity",
            "nonSolicitation",
            "daaq" /*"consent", "facility"*/,
          ],
        },
      },
      /*{
                id: 'POSTAL_MAIL_CONTACT',
                label: 'Postal Mail',
                config: {
                    objectType: 'odm.party.partycontactmechanism.postalmailcontact',
                    fields: ["address", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", /!*"consent", "facility"*!/]
                }
            },*/
    ],
  });
}

export function getIdProof(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_party,
    items: [
      {
        id: "CARTE_IDENTITE",
        label: "Carté identité",
      },
      {
        id: "PASSPORT",
        label: "Passeport",
      },
    ],
  });
}

export function getFacilityType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-facility-type",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName: "facilitytype",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getQualificationType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-qualification-type",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid : odm_party,
          entityName: "qualificationtype",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getAccountType(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_party,
    items: [
      {
        id: "DEPOSIT",
        label: i18n.global.t(`party.account.type.deposit`),
      },
      {
        id: "CASH",
        label: i18n.global.t(`party.account.type.cash`),
      },
    ],
  });
}

export function getAccountStatus(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_party,
    items: [
      {
        id: "ACTIVE",
        label: "ACTIVE",
      },
      {
        id: "BLOCKED",
        label: "BLOCKED",
      },
    ],
  });
}

//PickList Asset

export function getActivityAsset(): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'product-inventory-type',
      }
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })

    axios.get(url, {
      headers: _headers
    }).then(res => {

      const response = {
        systemUid: odm_product,
        entityName: "inventorytype",
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.id,
            label: resItem.id ,
            config: {
              internalCode : resItem.internal_code,
            }
          }
        })

      }

      resolve(response)

    }).catch((error) => {
      reject(error);
    });
  })
}

export function getColorsAsset(): Promise<ComboBox> {
  const filter: any = { category_id: "COLOR" }
  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'product-product-category-class',
      }
    }
    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []

    operators.push(getOperator("product_product_category_class", "category_id", "EQUAL", `'${filter.category_id}'`, "category_id"))


    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios.get(url, {
      headers: _headers
    }).then(res => {

      const response = {
        systemUid: odm_product,
        entityName: "productcategoryclass",
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.id,
            label: resItem.id.replace("COLOR.", "")
          }
        })

      }

      resolve(response)

    }).catch((error) => {
      reject(error);
    });
  })
}

export function getUsageAsset(): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'asset-usage-picklist',
      }
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })

    axios.get(url, {
      headers: _headers
    }).then(res => {

      const response = {
        systemUid: odm_offer,
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.id,
            label: resItem.id,
          }
        })

      }

      resolve(response)

    }).catch((error) => {
      reject(error);
    });
  })
}

export function getCategoriesAsset(): Promise<Picklist> {
  const filter: any = { id: "asset.good.Good.Family" }
  return new Promise<Picklist>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'product-asset-category',
      }
    }
    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []

    operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))


    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })

    axios.get(url, {
      headers: _headers
    }).then(res => {

      const response = {
        systemUid: odm_product,
        entityName: "productcategory",
        items: res.data.map((resItem: any) => {
          return {
            id: resItem.id,
            label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
            config: {
              name: filter.id
            }
          }
        })

      }

      resolve(response)

    }).catch(err => {


      resolve({
        systemUid: '',
        items: [
          {
            id: 'odm-party',
            label: 'Crée2'
          },

        ]
      })
    });


  })
}

export function getBodiesAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "SUV",
      },
      {
        id: "2",
        label: "Hatch",
      },
    ],
  });
}

export function getBrandsAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "Citroen",
      },
      {
        id: "2",
        label: "Pegeout",
      },
      {
        id: "3",
        label: "Hyundai",
      },
      {
        id: "4",
        label: "BMW",
      },
      {
        id: "5",
        label: "Toyota",
      },
    ],
  });
}

export function getModelsAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "1.0",
      },
      {
        id: "2",
        label: "2.0",
      },
    ],
  });
}

export function getFinishesAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "Farmer",
      },
      {
        id: "2",
        label: "Doctor",
      },
    ],
  });
}

export function getFuelTypesAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "eletric",
      },
      {
        id: "2",
        label: "hybrid",
      },
    ],
  });
}

export function getEmissionsCO2Asset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "120g/Km",
      },
      {
        id: "2",
        label: "260g/Km",
      },
    ],
  });
}

export function getPowersHPAsset(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        id: "1",
        label: "80HP",
      },
      {
        id: "2",
        label: "110HP",
      },
    ],
  });
}

export function getNatureBalanceSheet(): Promise<Picklist> {
  return new Promise<Picklist>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "nature-balance-sheet",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTypeCredits(): Promise<ComboBox> {
  return Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        value: "1",
        label: "Consommation",
      },
      {
        value: "2",
        label: "Immobilier",
      },
      {
        value: "3",
        label: "Revolving",
      },
      {
        value: "4",
        label: "Rac Consommation",
      },
      {
        value: "5",
        label: "Rac Immobilier",
      },
      {
        value: "6",
        label: "Dette Perso",
      },
      {
        value: "7",
        label: "Dette Fiscale",
      },
      {
        value: "8",
        label: "Soulte",
      },
      {
        value: "9",
        label: "Dette pro",
      },
    ],
  });
}

export function getSubventionTypesOptions(): Promise<ComboBox> {
  return Promise.resolve({
    items: [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Aide Bonus Modele', value: 'aide_bonus_modele' }
    ],
  });
}

/*export function getSubventionOptions(): Promise<ComboBox> {
  return Promise.resolve({
    items: [
    { label: 'MANUFACTURER', value: 'MANUFACTURER' },
    { label: 'ADDITIONAL', value: 'ADDITIONAL' }
    ],
  });
}*/

export function getSubventionOptions(): Promise<ComboBox> {
  const qc_header: any = {
      qc: {
          queryId: "offer-picklist-brand",
          limit: 100
      },
  };
  const operators = []
  operators.push(getOperator("product_product_category_class", "category_id", "LIKE",`'SUBSIDY_TYPE'`, "category_id"));

  if ( operators.length > 0 ) {
      qc_header.qc.otherFilters = {
          expressions: [
              {
                  and: {
                      operators
                  }
              }
          ]
      }
  }
  return new Promise<ComboBox>((resolve, reject) => {
      const _headers = headers({
          qc: JSON.stringify(qc_header.qc),
      });
      axios
          .get(api_query_url, {
              headers: _headers,
          })
          .then((res) => {
              const response = {
                  systemUid: odm_product,
                  entityName: 'product_product_category_class',
                  items: res.data.map((res: any) => {
                      return {
                          value: res.id,
                          label: res.id
                      };
                  }),
              };
              resolve(response);
          })
          .catch((err) => reject(err));
  });
}

export const getOrganization: () => Promise<ComboBox> = () =>
  new Promise((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "suppliers-picklist",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((response) => {
        resolve({
          systemUid: odm_party,
          items: response.data,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getObjects: () => Promise<ComboBox> = () =>
  Promise.resolve({
    systemUid: odm_asset,
    items: [
      {
        value: "1",
        label: "Perspectives",
      },
    ],
  });

export function getActionType(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_offer,
    items: [
      {
        id: "enter-customer-acceptance",
        label: "Enter customer acceptance",
      },
      {
        id: "evaluate-checklist",
        label: "Evaluate checklist",
      },
      {
        id: "verify-all-doc-received",
        label: "Verify all doc received",
      },
    ],
  });
}
export function getDecisionStatus(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "decision-picklist-status",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_decision,
          entityName: "decisionstatus",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t(`decision.status.${resItem.id}`),
              config: {
                              internalCode : resItem.internal_code,
                            }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getDecisionMotif(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "decision-picklist-reason",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid : odm_decision,
          entityName: "decisionreason",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export function getDecisionReserve(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "decision-picklist-reserve",
      offset: 0,
      limit: 150,
    },
  };
  return new Promise<ComboBox>((resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid : odm_decision,
          entityName: "decisionreserve",
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t(`task.reserves.${resItem.id}`),
              config: {
                selected: false,
                comment: ""
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getAcceptDecisionMotif(): Promise<ComboBox> {
  return Promise.resolve({
    systemUid: odm_decision,
    items: [
      {
        value: "Client non satisfait",
        label: "Client non satisfait",
      },
      {
        value: "Taux non adapté",
        label: "Taux non adapté",
      },
      {
        value: "Concurrence plus intéréssante",
        label: "Concurrence plus intéréssante",
      },
    ],
  });
}

export const fetchReasons = async (status: string) => {
  const { response, error } = await useApi(UON.SystemUid.odmDecision, `decision/${status}/reason`).get();
  if (error) {
    throw error;
  }
  return response;
};

export const getPartyRelationTypes = async (): Promise<ComboBox> => {
  const qc_header = {
    queryId: "picklist-party-relation-type",
    offset: 0,
    limit: 150,
    parameters: {
      query: 'DIRG'
    },
  };
  const _headers = headers({ qc: JSON.stringify(qc_header) });
  const { data } = await axios.get(api_query_url, { headers: _headers });
  return {
    systemUid : odm_party,
    entityName: "relationtype",
    items: data.map((el: { id : string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.id,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}

export const getLegalCategory = async (): Promise<ComboBox> => {
  const qc_header = {
    queryId: "picklist-party-organization-legal-category",
    offset: 0,
    limit: 150
  };
  const _headers = headers({ qc: JSON.stringify(qc_header) });
  const { data } = await axios.get(api_query_url, { headers: _headers });
  return {
    systemUid : odm_party,
    entityName: "organizationlegalcategory",
    items: data.map((el: PicklistItem) => {
      return {
        value: el.id,
        label: el.id
      }
    })
}
}

export function getLanguage(): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "language-picklist",
        offset: 0,
        limit: 150,
      },
    };
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_configuration,
          items: res.data.map((resItem: any) => {
            return {
              id: `${resItem.language_abbreviation}-${resItem.country_abbreviation}`,
              label: `${resItem.language_name}(${resItem.country_name})`,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) =>
        resolve({
          systemUid:odm_configuration,
          items: [
            { value: "INITIAL", label: "Initiale" },

          ],
        })
      );
  });

}

export function getSpecificLanguage(filter: any): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "language-picklist",
        offset: 0,
        limit: 150,
      },
    };

    const operators = []
    if (filter && filter.language != null) {
      const abbrevaiation = filter.language.slice(0, 2)
      operators.push(getOperator("configuration_language", "abbreviation", "EQUAL", globalConfig.formatters.getEqualValue(abbrevaiation), ""))
    }
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid:odm_configuration,
          items: res.data.map((resItem: any) => {
            return {
              id: `${resItem.language_abbreviation}-${resItem.country_abbreviation}`,
              label: `${resItem.language_name}(${resItem.country_name})`,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) =>
        resolve({
          systemUid:odm_configuration,
          items: [
            { value: "INITIAL", label: "Initiale" },

          ],
        })
      );
  });

}

export const getScaleFinancialProfile = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-financial-profile-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    entityName: 'financialprofile',
    items: data.map((el: { id: string }) => {
      return {
        value: el.id,
        label: el.id.substring(el.id.indexOf('.') + 1, el.id.length)
      }
    })
  }
}

export const getScaleStatus = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-status-code-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    items: data.map((el: { id : string, internal_code : string}) => {
      return {
        value: el.id,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}

export const getScaleCategory = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-category-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    entityName: 'productcategory',
    items: data.map((el: { id : string,label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}

export const getScaleClassification = async (categoryId: string) => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-classification-picklist",
      offset: 0,
      limit: 150,
      parameters: { category_id: categoryId }
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    entityName: 'productclassification',
    items: data.map((el: { id: string, label: string }) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}
export const getScaleProductFrequency = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-product-frequency",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid: odm_product,
    entityName: 'frequency',
    items: data.map((el: { id : string, label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}

export const getScaleRateBasis = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-product-rate-basis",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    entityName : 'ratebasis',
    items: data.map((el: { id: string, label: string }) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}

export const getScaleGracePeriodCalculationMethod = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-product-grace-period-calculation-method",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    items: data.map((el: { id : string,label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}



export const getFinancialInstitution = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "party-financial-institution",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_party,
    items: data.map((el: { id : string}) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}
export const getTypeOutstanding = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "party-outstanding-type",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_party,
    items: data.map((el: { id : string}) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}

export const getProductTax = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-tax",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid : odm_product,
    items: data.map((el: { code: string, method_code: string, validity_from: string, validity_until: string }) => {
      return {
        value: el.code,
        label: el.code
      }
    })
  }
}

export const getServiceType = async () => {
  return {
    items: [
      {
        value: "simpleserviceproduct",
        label: i18n.global.t('scales.picklist.serviceTypes.simpleserviceproduct')
      },
      {
        value: "maintenanceproduct",
        label: i18n.global.t('scales.picklist.serviceTypes.maintenanceproduct')
      },
      {
        value: "partyinsuranceproduct",
        label: i18n.global.t('scales.picklist.serviceTypes.partyinsuranceproduct')
      },
      {
        value: "assetinsuranceproduct",
        label: i18n.global.t('scales.picklist.serviceTypes.assetinsuranceproduct')
      },
      {
        value: "originationcommissionproduct",
        label: i18n.global.t('scales.picklist.serviceTypes.originationcommissionproduct')
      },
    ]
  }
}

export const getProductBasis = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-basis-picklist",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id : string,label:string, internal_code : string, _daaq:string}) => ({
    value: el.id,
    label: i18n.global.t(`scales.picklist.productBasis.${el.id}`),
    config: {
      _daaq: el._daaq,
      internalCode: el.internal_code
    }
  }))

  return { items, systemUid : odm_product, entityName:'financingbasis' };
}

export const getAccessoryCalculationMethod = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "accessory-calculation-method-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id : string,label:string, internal_code : string ,_daaq:string} ) => ({
    value: el.id,
    label: el.id,
    config: {
      _daaq: el._daaq,
      internalCode : el.internal_code
    }
  }));

  return { items, systemUid : odm_product };
}

export const getProductTaxServices = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-tax",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  type ProductTax = {
    code: string,
    method_code: string,
    validity_from: string,
    validity_until: string,
    _daaq: string
  }

  const isValid = (el: ProductTax) => {
    return useDate.isPeriodValid(
      useDate.currDate,
      <string>useDate.sqlFormat(el.validity_from),
      <string>useDate.sqlFormat(el.validity_until)
    )
  }

  const items = data.filter((el: ProductTax) => isValid(el))
    .map((el: ProductTax) => ({
      value: el.code,
      label: el.code,
      config: {
        _daaq: el._daaq
      }
    }))

  return { items, systemUid : odm_product };
}

export const getAccessoryPaymentDatesOption = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "accessory-payment-dates-option-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id : string,label:string, internal_code : string, _daaq:string}) => ({
    value: el.id,
    label: el.id,
    config: {
      _daaq: el._daaq,
      internalCode : el.internal_code
    }
  }));

  return { items, systemUid : odm_product};
}
/**
* Fetches the asset types from the API.
* @return {Promise<{items: AssetType[]}>} A promise that resolves to an object with a list of asset types.
*/
export function getAssetType(): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {

  const qc_header: any = {
    qc: {
      queryId: "product-product-category-class",
      offset: 0,
      limit: 150,
    },
  };

  const operators = []


    operators.push(getOperator("product_product_category_class", "category_id", "EQUAL", "'NATURE'", "category_id"))
  
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
    qc_header.qc.otherOrders = null
    qc_header.qc.offset = 0
    qc_header.qc.limit = 150
  }

  const _headers = headers({
    qc: JSON.stringify(qc_header.qc)
  })
  axios
    .get(api_query_url, {
      headers: _headers,
    })
    .then((res) => {
      const excludedValues = ['NATURE.CARPACK', 'NATURE.OPTION', 'NATURE.ACCESSORY', 'NATURE.ASSETFEE', 'NATURE.EQUIPMENTPACK'];
      const filteredData = res.data.filter((el: { category_id: string, id: string }) => {
        return el.category_id === 'NATURE' && !excludedValues.includes(el.id);
      });

      const response = {
        systemUid: odm_product,
        entityName: "productcategoryclass",
        items: filteredData.map((resItem: any) => {
          if(!excludedValues.includes(resItem.id)){
            return {
              value: resItem.id.replace("NATURE.", ""),
              label: resItem.id
            };
          }
        }),
      };
      resolve(response);
    })
    .catch((err) =>
      resolve({
        systemUid: odm_product,
        items: [
          { value: "INITIAL", label: "Initiale" },

        ],
      })
    );
  })

}

/**
* Fetches the asset statuses from the API.
* @return {Promise<{items: AssetStatus[]}>} A promise that resolves to an object with a list of asset statuses.
*/
export const getAssetStatut = async () => {
  // Creates a QC header object with the query ID for asset statuses.
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-product-status",
      offset: 0,
      limit: 150
    }
  };
  // Makes a GET request to the API to fetch the asset statuses.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of asset statuses.
  return {
    systemUid : odm_product,
    items: data.map((el: { id : string,label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode : el.internal_code,
        }
      }
    })
  }
}

/**
* Fetches the asset catalogs from the API.
* @return {Promise<{items: AssetCatalog[]}>} A promise that resolves to an object with a list of asset catalogs.
*/



export const getAssetCatalog = async () => {
  // Creates a QC header object with the query ID for asset catalogs.
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-product-catalog-search",
      offset: 0,
      limit: 150
    }
  };
  // Makes a GET request to the API to fetch the asset catalogs.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of asset catalogs
  return {
    systemUid : odm_product,
    entityName : 'productcatalog',
    items: data.map((el: { reference: string }) => {
      return {
        value: el.reference,
        label: el.reference
      }
    })
  }
}

/**
* Fetches the garanty status from the API.
* @return {Promise<{items: GarantyStatus[]}>} A promise that resolves to an object with a list of garanty status.
*/
export const getGarantyStatus = async () => {
  // Creates a QC header object with the query ID.
  const qc_header: QcHeader = {
    qc: {
      queryId: "scale-status-code-picklist",
      offset: 0,
      limit: 150
    }
  };
  // Makes a GET request to the API to fetch the garanty status.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of garanty status.
  return {
    systemUid : odm_product,
    items: data.map((el: { id : string,label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode: el.internal_code
        }
      }
    })
  }
}

/**
* Fetches the garanty type from the API.
* @return {Promise<{items: GarantyType[]}>} A promise that resolves to an object with a list of garanty type.
*/
export const getGarantyType = async () => {
  // Creates a QC header object with the query ID.
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-asset-category",
      offset: 0,
      limit: 150
    }
  };
  const operators = []
  operators.push(getOperator("product_product_category_class", "id", "LIKE", globalConfig.formatters.getLikeValue("GUARANTEE_TYPE"), "id"))
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
  // Makes a GET request to the API to fetch the garanty type.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of garanty type.
  return {
    systemUid : odm_product,
    entityName : 'productcategory',
    items: data.map((el: { id: string }) => {
      const modifiedId = el.id.replace("GUARANTEE_TYPE.", "");
      return {
        value: modifiedId,
        label: modifiedId
      }
    })
  }
}

/**
 * Retrieves the underlying types asynchronously from the API.
 *
 * @returns {Promise<ComboBox>} A promise resolving to ComboBox data with underlying types.
 */
export function getUnderlyingType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "product-picklist-underlyingtype",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_product,
          entityName : 'underlyingtype',
          items: res.data.map((resItem: any) => {
            return {
              label: resItem.label,
              value: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function getSignatureMethods(): Promise<ComboBox> {
  /*const qc_header: any = {
    qc: {
      queryId: "offer-picklist-signature-method",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_offer,
          items: res.data.map((resItem: any) => {
            return {
              label: resItem.id,
              value: resItem.id,
              config: {
                internalCode: resItem.internal_code
              }
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });*/
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        value: "FRA",
        label: "France",
      },
      {
        value: "MAR",
        label: "Marroco",
      },
    ],
  });
}
export function getPartyClassificationType(): Promise<ComboBox> {
  const qc_header: any = {
    qc: {
      queryId: "party-classification-type",
    },
  };
  return new Promise<ComboBox>(async (resolve, reject) => {
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: odm_party,
          entityName : 'partyclassification',
          items: res.data.map((resItem: any) => {
            return {
              label: resItem.id,
              value: resItem.id,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

/**
* Fetches the garanty status from the API.
* @return {Promise<{items: GarantyBasis[]}>} A promise that resolves to an object with a list of garanty status.
*/
export const getGarantyBasis = async () => {
  // Creates a QC header object with the query ID.
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-basis-picklist",
      offset: 0,
      limit: 150
    }
  };
  // Makes a GET request to the API to fetch the garanty basis.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of garanty basis.
  return {
    systemUid : odm_product,
    entityName : 'financingbasis',
    items: data.map((el: { id : string,label:string, internal_code : string}) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode: el.internal_code
        }
      }
    })
  }
}

/**
 * Retrieves Garanty rules asynchronously from the API.
 *
 * @returns {Promise<ComboBox>} A promise resolving to ComboBox data with Garanty rules.
 */
export function getGarantyRules(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "product-guarantee-patyrole",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_product,
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t('demand.party.roles.'.concat(resItem.id)).toUpperCase(),
              config: {
                internalCode : resItem.internal_code,
              },
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getProductAvailabilityContext = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "product-availability-context-picklist",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { context: string, _daaq: string }) => ({
    value: el.context,
    label: el.context, // ? i18n.global.t(`availabilities.picklist.context.${el.context}`) : '',
    config: {
      _daaq: el._daaq
    }
  }))

  return { items, systemUid : odm_product, entityName : 'productavailability'  };
}


export const getGender = async () => {
  // Creates a QC header object with the query ID.
  const qc_header: QcHeader = {
    qc: {
      queryId: "party-gender",
      offset: 0,
      limit: 150
    }
  };
  // Makes a GET request to the API to fetch the garanty basis.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of garanty basis.
  return {
    systemUid : odm_party,
    entityName : 'gender',
    items: data.map((el: { gendercode: string }) => {
      return {
        value: el.gendercode,
        label: el.gendercode
      }
    })
  }
}


export const getcontractType = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-contract-type",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });

  return {
    systemUid : odm_party,
    items: data.map((el: { contracttypecode: string }) => {
      return {
        value: el.contracttypecode,
        label: el.contracttypecode
      }
    })
  }
}

export const getpropertyStatus = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-living-situation",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });

  return {
    systemUid : odm_party,
    entityName : 'livingsituation',  
    items: data.map((el: { livingsituationcode: string }) => {
      return {
        value: el.livingsituationcode,
        label: el.livingsituationcode
      }
    })
  }
}

export const getdocumentType = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-identity-document-type",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });

  return {
    systemUid : odm_party,
    entityName : 'identitydocument',
    items: data.map((el: { identitydocumenttypecode: string }) => {
      return {
        value: el.identitydocumenttypecode,
        label: el.identitydocumenttypecode
      }
    })
  }
}
export const getOrderStatus = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "order-status-picklist",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id: string, internal_code: string }) => ({
    value: el.id,
    label: i18n.global.t("main.dossier.orderStatus." + el.id),
    config: {
      internalCode: el.internal_code
    }
  }))

  return { items, systemUid : odm_order, entityName : 'orderstatus' };
}
export const getManualTask = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "order-manual-task-picklist",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id: string, internal_code: string }) => ({
    value: el.id,
    label: i18n.global.t("task.actions." + el.id),
    config: {
      internalCode: el.internal_code
    }
  }))

  return { items, systemUid : odm_order, entityName : 'orderManualTask' };
}

export const getPreconditionsStatus = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "supportingdocument-check-points-status",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { preconditionsstatus: string }) => ({
    value: el.preconditionsstatus,
    label: i18n.global.t("main.dossier.preconditionStatus." + el.preconditionsstatus)
  }))

  return { items, systemUid : odm_order, entityName : 'supportingdocument_check_points_status' };
}
export const getPreconditionsConfig = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "supportingdocument-check-points-list",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { preconditionsid: string }) => ({
    value: el.preconditionsid,
    label: i18n.global.t("main.dossier.precondition." + el.preconditionsid)
  }))

  return { items, systemUid : odm_order, entityName : 'supportingdocument_check_points_config' };
}
export const getOrderNetworkNodeList = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-network-node",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const filtredData = data.filter((item: any) => item.network_id === "BODEMER_RESEAUX")
  const items = filtredData.map((el: { id: string, network_node_id:string,network_id:string }) => ({
    value: el.id,
    label: i18n.global.t("main.dossier.networkNode." + el.id)
  }))

  return { items, systemUid : odm_order, entityName : 'supportingdocument_check_points_config' };
}


export const getOrderItemStatus = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "order-item-status-picklist",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id: string, internal_code: string }) => ({
    value: el.id,
    label: i18n.global.t("main.dossier.orderStatusItems." + el.id),
    config: {
      internalCode: el.internal_code
    }
  }))

  return { items };
}


export const getDecisionOpinion = async () => {
  // Creates a QC header object with the query ID.
  const qc_header: QcHeader = {
    qc: {
      queryId: "decision-opinion-picklist",
      offset: 0,
      limit: 150
    }
  };
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  return {
    systemUid:odm_decision,
    entityName  : 'opinion',
    items: data.map((el: { id: string, label: string, internal_code :string }) => {
      return {
        value: el.id,
        label: el.label,
        config: {
          internalCode: el.internal_code
        }
      }
    })
  }
}
export function getModule(): Promise<ComboBox> {
  return Promise.resolve({
    systemUid: odm_workflow,
    items: [
      {
        value: "process.module.order",
        label: "process.module.order",
      },
      {
        value: "process.module.offer",
        label: "process.module.offer",
      }
    ],
  });
}

export const getDelegationLevels = async () => {
  const qc_header: QcHeader = {
    qc: {
      queryId: "decision-delegation-levels",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) })
  });

  const items = data.map((el: { id: string, i_18n_code: string }) => ({
    value: el.id,
    label: i18n.global.t("decision.label." + el.id),
    config: {
      i_18n_code: el.i_18n_code
    }
  }))

  return { items,systemUid : odm_decision };
}

/**
* Fetches the user security group from the API.
* @return {Promise<{items: UserSecurityGroup[]}>} A promise that resolves to an object with a list of user security group.
*/
export const getUserSecurityGroup = async () => {
  // Creates a QC header object with the query ID for user security group.
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-security-group",
      offset: 0,
      limit: 1000
    }
  };
  // Makes a GET request to the API to fetch the user security group.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of user security group
  return {
    systemUid : odm_user,
    entityName : 'securitygroup',
    items: data.map((el: { id: string }) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}


/**
* Fetches the user team from the API.
* @return {Promise<{items: UserTeam[]}>} A promise that resolves to an object with a list of user network.
*/
export const getUserTeam = async () => {
  // Creates a QC header object with the query ID for user team.
  const qc_header: QcHeader = {
    qc: {
      queryId: "user-team",
      offset: 0,
      limit: 100
    }
  };
  // Makes a GET request to the API to fetch the user team.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });

  store.dispatch('userModule/setManagers', data)


  // Maps the data to a list and returns an object with the list of user team
  return {
    systemUid : odm_user,
    entityName : 'team',
    items: data.map((el: { id: string, manager_code: string }) => {
      return {
        value: el.id,
        label: el.id,
        config: {
          manager_code: el.manager_code
        }

      }
    })
  }
}
/**
* Fetches the network from the API.
* @return {Promise<{items: UserTeam[]}>} A promise that resolves to an object with a list of user team.
*/
export const getuserNetwork = async () => {
  // Creates a QC header object with the query ID for network.
  const qc_header: QcHeader = {
    qc: {
      queryId: "party-network",
      offset: 0,
      limit: 100
    }
  };
  // Makes a GET request to the API to fetch the network.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of network
  return {
    systemUid : odm_party,
    entityName : 'network',
    items: data.map((el: { id: string }) => {
      return {
        value: el.id,
        label: el.id,
        config: {
          id: el.id
        }
      }

    })
  }
}

export function getStatusOrderList(): Promise<ComboBox> {

  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: 'order-status-picklist',
      }
    }
   
    const operators = []

    operators.push(getOperator("order_order_status", "internal_code", "EQUAL", `'DELIVERY'`, "category_id"))


    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios.get(url, {
      headers: _headers
    }).then(res => {

      const response = {
        systemUid: odm_product,
        entityName: "orderstatus",
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.id,
            label: resItem.id,
            config: {
              internalCode: resItem.internal_code
            }
          }
        })

      }

      resolve(response)

    }).catch((error) => {
      reject(error);
    });
  })
}


/**
* Fetches the networkNode from the API.
* @return {Promise<{items: UserTeam[]}>} A promise that resolves to an object with a list of user network node.
*/
export const getuserNetworkNode = async () => {
  // Creates a QC header object with the query ID for network node.
  const qc_header: QcHeader = {
    qc: {
      queryId: "party-network-node",
      offset: 0,
      limit: 100
    }
  };
  // Makes a GET request to the API to fetch the network node.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });

  // Maps the data to a list and returns an object with the list of network node
  return {
    systemUid : odm_party,
    entityName : 'networknode',
    items: data.map((el: { id: string, network_id: string, network_node_id: string }) => {
      return {
        value: el.network_node_id,
        label: el.network_node_id,
        config: {
          id: el.id,
          network_id: el.network_id
        }
      }
    })
  }
}

export function getJustifyTypes(): Promise<Picklist> {
  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "DOC_reserve",
        label: "DOC_reserve",
      }
    ],
  });
}

export const getSupportingDocumentType = async () => {

  return Promise.resolve({
    systemUid: odm_simulation,
    items: [
      {
        id: "Autres",
        label: "Autres",
      },
    ],
  });
}
export function getAdditionalInformation(filter: any): Promise<any> {
  return new Promise<any>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "supportingdocument-check-points",
        offset: 0,
        limit: 50,
      },
    };

    const operators = []
    if (filter && filter.id != null) {
      operators.push(getOperator("supportingdocument_check_points", "check_point_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.id), ""))
    }
    if (filter && filter.idCheck != null) {
      operators.push(getOperator("supportingdocument_check_points", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.idCheck), ""))
    }
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });

}



export const getOrderTypeFinancing = async () => {
  // Creates a QC header object with the query ID for user security group.
  const qc_header: QcHeader = {
    qc: {
      queryId: "order-financing-type",
      offset: 0,
      limit: 1000
    }
  };
  // Makes a GET request to the API to fetch the user security group.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of user security group
  return {
    systemUid : odm_order,
    entityName : 'orderfinancingtype',
    items: data.map((el: { id: string }) => {
      return {
        value: el.id,
        label: el.id
      }
    })
  }
}

export const getOrderServiceType = async () => {
  // Creates a QC header object with the query ID for user security group.
  const qc_header: QcHeader = {
    qc: {
      queryId: "order-service-type-picklist",
      offset: 0,
      limit: 1000
    }
  };
  // Makes a GET request to the API to fetch the user security group.
  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  // Maps the data to a list and returns an object with the list of user security group
  return {
    systemUid: odm_order,
    entityName: 'orderassetservicetype',
    items: data.map((el: { id: string, internal_code : string }) => {
      return {
        value: el.id,
        label: el.id,
        config : {
          internalCode: el.internal_code
        }
      }
    })
  }
}
export function getroleOrder(): Promise<ComboBox> {
  return new Promise<ComboBox>((resolve, reject) => {
    return axios
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "order-party-role-picklist",
            offset: 0,
            limit: 150,
          }),
        }),
      })
      .then((res) => {
        const response = {
          systemUid: odm_order,
          entityName: 'orderpartyrole',
          items: res.data.map((resItem: any) => {
            return {
              value: resItem.id,
              label: i18n.global.t('demand.party.roles.'.concat(resItem.id)).toUpperCase(),
            };
          }),
        };
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getUsersSchedule(): Promise<ComboBox> {
  const qc_header: any = {
      qc: {
          queryId: "users-leaseforge-generic",
          limit: 100
      },
  };
  const operators = []
  operators.push(getOperator("user_lease_forge_user", "enabled", "EQUAL", true, "enabled"))
  operators.push(getOperator("user_daaq", "read_allowed", "EQUAL", true, "read_allowed"))

  if ( operators.length > 0 ) {
      qc_header.qc.otherFilters = {
          expressions: [
              {
                  and: {
                      operators
                  }
              }
          ]
      }
  }
  return new Promise<ComboBox>((resolve, reject) => {
      const _headers = headers({
          qc: JSON.stringify(qc_header.qc),
      });
      axios
          .get(api_query_url, {
              headers: _headers,
          })
          .then((res) => {
              const response = {
                  systemUid: odm_user,
                  entityName: 'userleaseforgeuser',
                  items: res.data.map((user: any) => {
                      return {
                          value: user.id,
                          label: user.first_name +' '+user.last_name,
                      };
                  }),
              };
              resolve(response);
          })
          .catch((err) => reject(err));
  });
}
export const getAssetDocumentType = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "asset-document-type",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { assetdocumenttype: string }) => ({
    value: el.assetdocumenttype,
    label: i18n.global.t("asset.document.type." + el.assetdocumenttype)
  }))

  return { items, systemUid : odm_order, entityName : 'product_asset_product_document_type' };
}
export const getPartyDocumentType = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-document-type",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { partydocumenttype: string }) => ({
    value: el.partydocumenttype,
    label: i18n.global.t("party.document.type." + el.partydocumenttype)
  }))

  return { items, systemUid : odm_party, entityName : 'party_party_document_type' };
}

export const getNetworkNodes = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "party-network-node-distinct",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { id: string }) => ({
    value: el.id,
    label: i18n.global.t("users.networkNode." + el.id)
  }))

  return { items, systemUid : odm_user, entityName : 'party_network_node' };
}

export const getPayPlanFrequency = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "order-pay-plan-frequency",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { id: string }) => ({
    value: el.id,
    label: el.id,
  }))

  return { items, systemUid : odm_user, entityName : 'order_pay_plan_frequency' };
}
export const getPayPlanActivity = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "order-pay-plan-objective-activity",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { id: string }) => ({
    value: el.id,
    label: el.id,
  }))

  return { items, systemUid : odm_user, entityName : 'order_pay_plan_objective_activity' };
}
export const getPayPlanStatus = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "order-pay-plan-objective-status",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { id: string }) => ({
    value: el.id,
    label: el.id,
  }))

  return { items, systemUid : odm_user, entityName : 'order_pay_plan_objective_status' };
}

export function getBasis(): Promise<ComboBox> {
  const qc_header: any = {
      qc: {
          queryId: "order-criteria-definition",
          limit: 100
      },
  };
  const operators = []
  operators.push(getOperator("order_sub_criteria_definition", "id", "ISNULL"));

  if ( operators.length > 0 ) {
      qc_header.qc.otherFilters = {
          expressions: [
              {
                  and: {
                      operators
                  }
              }
          ]
      }
  }
  return new Promise<ComboBox>((resolve, reject) => {
      const _headers = headers({
          qc: JSON.stringify(qc_header.qc),
      });
      axios
          .get(api_query_url, {
              headers: _headers,
          })
          .then((res) => {
              const response = {
                  systemUid: odm_order,
                  entityName: 'ordercriteriadefinition',
                  items: res.data.map((res: any) => {
                      return {
                          value: res.id,
                          label: res.id,
                      };
                  }),
              };
              resolve(response);
          })
          .catch((err) => reject(err));
  });
}
export function getDetailsBasis(): Promise<ComboBox> {
  const qc_header: any = {
      qc: {
          queryId: "order-criteria-definition",
          limit: 100
      },
  };
  const operators = []
  operators.push(getOperator("order_sub_criteria_definition", "id", "ISNOTNULL"));

  if ( operators.length > 0 ) {
      qc_header.qc.otherFilters = {
          expressions: [
              {
                  and: {
                      operators
                  }
              }
          ]
      }
  }
  return new Promise<ComboBox>((resolve, reject) => {
      const _headers = headers({
          qc: JSON.stringify(qc_header.qc),
      });
      axios
          .get(api_query_url, {
              headers: _headers,
          })
          .then((res) => {
              const response = {
                  systemUid: odm_order,
                  entityName: 'ordercriteriadefinition',
                  items: res.data.map((res: any) => {
                      return {
                          value: res.id,
                          label: res.id,
                      };
                  }),
              };
              resolve(response);
          })
          .catch((err) => reject(err));
  });
}
export function getVendorsList(): Promise<ComboBox> {
  return new Promise<ComboBox>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "picklist-vendors-list",
      },
    };
    const operators = [];
      operators.push(
        getOperator(
          "party_party_role",
          "role_code",
          "EQUAL",
          globalConfig.formatters.getEqualValue('VENDOR'),
          "role_code"
        )
      );
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 50;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: "odm-party",
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.id,
              label: resItem.family_name +' '+resItem.first_name,
              config:resItem
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}
export const getPayPlanObjective = async () => {

  const qc_header: QcHeader = {
    qc: {
      queryId: "order-picklist-payplan-objective",
      offset: 0,
      limit: 150
    }
  };

  const { data } = await api().get(api_query_url, { headers: headers({ qc: JSON.stringify(qc_header.qc) }) });
  const items = data.map((el: { id: string ,label:string}) => ({
    value: el.id,
    label: el.label,
  }))

  return { items, systemUid : odm_user, entityName : 'order_pay_plan_objective' };
}

import {Options, Vue} from "vue-class-component";


@Options({
   props: ["orderConditions"],
  components: {
  },
})
export default class summaryTabOrderFeatures extends Vue {
//isChecked :boolean = true
orderConditions?: Array<any>;}

import { saveOrderPayPlanObjective,searchOrderPayPlanObjective,handleGetOrderPayPlanObjective, searchOrderSubCriteriaDefinition, deleteObjective, searchObjectiveList, searchObjectivePrimeList } from "@/store/services/objective/objectiveService";
import { getVendorsList, getVendorsRows, saveObejectiveSales, searchPayplanList } from "@/store/services/order/orderService";
import { ObjectiveState } from "@/types";
import { Module } from "vuex";
import i18n from "@/i18n";
import Notify from 'quasar/src/plugins/Notify.js';;

const objectiveModule: Module<ObjectiveState, unknown> = {
  namespaced: true,
  state: {
    isLoading: false,
    openObjective: false,
    objectiveFilterRef: "",
    vendorsList:[],
    selectedObjective: {
      reference:"",
      label: "",
      startPeriod: "",
      endPeriod: "",
      objectType: "odm.order.payplanobjective",
      systemUid: "odm-order",
      status: "",
      frequency: "",
      activity: "",
      basis:{},
      criterea: [],
      primes:[],
      eligibility:[]
    },
  },
  getters: {
    initSelectedObjectif(){
      return {
        reference:"",
        label: "",
        startPeriod: "",
        endPeriod: "",
        objectType: "odm.order.payplanobjective",
        systemUid: "odm-order",
        status: "",
        frequency: "",
        activity: "",
        basis: {
          criteria: 
                {objectType: 'odm.order.criteriadefinition',
                systemUid: 'odm-order',
                resourceUid: ''},
          targetValue: "",
          targetValueType:{
                objectType: "odm.order.targetvaluetype",
                systemUid: "odm-order",
                resourceUid: "PERCENTAGE"
          },
          details: [
                  {
                    criteria:{
                      objectType: 'odm.order.criteriadefinition',
                      systemUid: 'odm-order',
                      resourceUid: ''
                    },
                      value: {
                          type: {
                              value: "",
                              label: ""
                          }
                      },
                      targetValue:"",
                      targetValueType:{
                        objectType: "odm.order.targetvaluetype",
                        systemUid: "odm-order",
                        resourceUid: "PERCENTAGE"
                      },
                      optionsList: []
                  }
              ],
              validated: true
          },
          criterea: [],
          primes: [
              {
                objectType: "odm.order.payplanobjectiveprime",
                systemUid: "odm-order",
                criteria: {
                  objectType: 'odm.order.criteriadefinition',
                  systemUid: 'odm-order',
                  resourceUid: ''
                },
                  brand: {
                    objectType: 'odm.order.criteriadefinition',
                    systemUid: 'odm-order',
                    resourceUid: ''
                  },
                  model: {
                        objectType: "odm.order.subcriteriadefinition",
                        systemUid: "odm-order",
                        resourceUid: ''
                    },
                  optionsList: [],
                  details: [
                      {
                          bloc: [
                              {
                                subCriteria: {
                                  objectType: "odm.order.subcriteriadefinition",
                                  systemUid: "odm-order",
                                  resourceUid: ''
                                },
                                targetValueOperator:{
                                  objectType: "odm.order.targetvalueoperator",
                                  systemUid: "odm-order",
                                  resourceUid: ''
                                },
                                  targetValue: "",
                                  targetValueType:{
                                    objectType: "odm.order.targetvaluetype",
                                    systemUid: "odm-order",
                                    resourceUid: "PERCENTAGE"
                                  }
                              }
                          ],
                          primeBonus: {
                            amount: 0.0,
                            currency: "EUR"
                          }
                      }
                  ]
              }
          ]
          
      
      }
    },
    getGeneralData: (state): ObjectiveState | undefined => {
      return state?.selectedObjective?.generalData;
    },
    getvendeurList: (state): ObjectiveState | undefined => {
      console.log(state?.vendorsList)
      return state?.vendorsList?.items||[];
    },
    getBrandModelInit(){
      return {
        criteria: {
          objectType: "odm.order.criteriadefinition",
          systemUid: "odm-order",
          resourceUid: ''
        },
          value: {
              type: {
                  value: "",
                  label: ""
              }
          },
          targetValue:"",
          targetValueType:{
            objectType: "odm.order.targetvaluetype",
            systemUid: "odm-order",
            resourceUid: "PERCENTAGE"
          },
          optionsList: []
      };
    },
    getBasisInit(){
      return {
        criteria:{
                objectType: 'odm.order.criteriadefinition',
                systemUid: 'odm-order',
                resourceUid: ''
              }
                ,
        criteriaValue:'',
        targetValueType:{
          objectType: "odm.order.targetvaluetype",
          systemUid: "odm-order",
          resourceUid: "PERCENTAGE"
      },
        details: [
          {
            criteria: {
              objectType: "odm.order.criteriadefinition",
              systemUid: "odm-order",
              resourceUid: ''
            },
            value: {
              type: {
                value: '',
                label: '',
              }
            },
            targetValue:"",
            targetValueType:{
                objectType: "odm.order.targetvaluetype",
                systemUid: "odm-order",
                resourceUid: "PERCENTAGE"
            },
            optionsList:[]
          }
        ],
        validated: false
      }
    },
    getCritereaInit() {
      return {
        criteria:{
          objectType: 'odm.order.criteriadefinition',
          systemUid: 'odm-order',
          resourceUid: ''
        },
        targetValue: "",
        targetValueType:{
          objectType: "odm.order.targetvaluetype",
          systemUid: "odm-order",
          resourceUid: "PERCENTAGE"
        },
        details: [
          {
            criteria:{
              objectType: 'odm.order.criteriadefinition',
              systemUid: 'odm-order',
              resourceUid: ''
            },
            value: {
              type: {
                value: '',
                label: '',
              }
            },
            targetValue:"",
            targetValueType:{
              objectType: "odm.order.targetvaluetype",
              systemUid: "odm-order",
              resourceUid: "PERCENTAGE"
            },
            optionsList:[]
          }
        ],
        validated: false
      }
    },
    getPrimesCritereaInit() {
      return {
        objectType: "odm.order.payplanobjectiveprime",
        systemUid: "odm-order",
        criteria: {
          objectType: 'odm.order.criteriadefinition',
          systemUid: 'odm-order',
          resourceUid: ''
        },
          brand: {
            objectType: 'odm.order.criteriadefinition',
            systemUid: 'odm-order',
            resourceUid: ''
          },
          model: {
                objectType: "odm.order.subcriteriadefinition",
                systemUid: "odm-order",
                resourceUid: ''
            },
          optionsList: [],
          details: [
              {
                  bloc: [
                      {
                        subCriteria: {
                          objectType: "odm.order.subcriteriadefinition",
                          systemUid: "odm-order",
                          resourceUid: ''
                        },
                        targetValueOperator:{
                          objectType: "odm.order.targetvalueoperator",
                          systemUid: "odm-order",
                          resourceUid: ''
                        },
                          targetValue: "",
                          targetValueType:{
                                    objectType: "odm.order.targetvaluetype",
                                    systemUid: "odm-order",
                                    resourceUid: "PERCENTAGE"
                          }
                      }
                  ],
                  primeBonus: {
                    amount: 0.0,
                    currency: "EUR"
                  }
              }
          ]
      }
    },
    getBlocInit() {
      return {
        subCriteria: {
          objectType: "odm.order.subcriteriadefinition",
          systemUid: "odm-order",
          resourceUid: ''
        },
        targetValueOperator:{
          objectType: "odm.order.targetvalueoperator",
          systemUid: "odm-order",
          resourceUid: ''
        },
        targetValue: "",
        targetValueType:{
          objectType: "odm.order.targetvaluetype",
          systemUid: "odm-order",
          resourceUid: "PERCENTAGE"
        }
      }
    },
    getPrimeInit() {
      return {
        bloc: [
            {
                subCriteria: {
                  objectType: "odm.order.subcriteriadefinition",
                  systemUid: "odm-order",
                  resourceUid: ''
                },
                targetValueOperator:{
                  objectType: "odm.order.targetvalueoperator",
                  systemUid: "odm-order",
                  resourceUid: ''
                } ,
                targetValue: "",
                targetValueType:{
                          objectType: "odm.order.targetvaluetype",
                          systemUid: "odm-order",
                          resourceUid: "PERCENTAGE"
                }
            }
        ],
        primeBonus: {
          amount: 0.0,
          currency: "EUR"
        }
      }
    }

  },
  actions: {
    initObjective({ commit,  getters }, payload) {
      const objective = getters.initSelectedObjectif
      commit('setObjective', objective);
      commit("showObjectiveDetail");
  },
  saveOrderPayPlanObjective({ state, dispatch }, payload) {
    saveOrderPayPlanObjective(state.selectedObjective).then((res: any) => {
      if (payload && payload.callback) {
          payload.callback(res)
      }
      if(res?.resourceUid){
          Notify.create({
              message: i18n.global.t("order.payplan.messages.createSuccess"),
              color: "positive",
          })
          dispatch('initOrderPayPlanObjectiveEditMode', {
              resourceUid: res.resourceUid
          })
      }else{
          Notify.create({
              message: 'ERROR: ' + res,
              color: 'negative'
          });
      }
    })
  },
  searchOrderPayPlanObjective({ commit }, payload) {
    const { filter,offset,limit, callback } = payload
    searchOrderPayPlanObjective(filter,offset,limit)
        .then(response => callback(response))
        .catch(e => console.error(e))
},
searchOrderSubCriteriaDefinition({ commit }, payload) {
  const { filter,offset,limit, callback } = payload
  searchOrderSubCriteriaDefinition(filter,offset,limit)
      .then(response => callback(response))
      .catch(e => console.error(e))
},
async initOrderPayPlanObjectiveEditMode({ commit}, payload) {
  const { resourceUid } = payload
  if (!resourceUid) {
      throw new Error('ResourceUid is mandatory!')
  }
  try {
      const orderPayPlanObjective = await handleGetOrderPayPlanObjective(resourceUid)
      commit('setObjective', orderPayPlanObjective);
      commit("showObjectiveDetail");
  } catch (e) {
      Notify.create({
          message: 'ERROR: ' + e,
          color: 'negative'
      })
  }
},
    showObjectiveDetail({ commit }, payload) {
        commit("showObjectiveDetail");
      },
      showScaleEdit({ commit }, payload) {
        commit("showObjectiveDetail");
      },
      removeCriterea({ commit }, payload) {
        commit("removeCriterea", payload)
    },
    vendorsList({ commit }, payload) {
      const { filter, callback } = payload;
      getVendorsList(filter)
        .then((res) => {
          console.log(res)
          commit("setVendorsList",res)
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    searchPayPlanList({ commit }, payload) {
      const { filter, callback } = payload;
      searchPayplanList(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    vendorsRows({commit},payload){
      const {filter,callback}=payload;
      getVendorsRows(filter).then((res) => {
        callback(res);
      })
      .catch((e) => console.error(e));

    },
    removeCritereaDetail({ commit }, payload) {
      commit("removeCritereaDetail", payload)
    },
      setCriterea({ commit }, payload) {
        commit("setCriterea", payload)
    },
    deleteObjective({ commit }, payload) {
      const { resourceUid } = payload
      deleteObjective(resourceUid)
          .then((res:any) => {
              Notify.create({
                  message: i18n.global.t("order.messages.deleteSuccess"),
                  color: "positive",
              })
              
          })
          .catch((err:any) => {
              console.error(err);
              Notify.create({
                message: err,
                color: "negative",
              });
          })
  },
  async searchObjectiveList({ commit }, payload) {
      const { filter, callback } = payload;
      await searchObjectiveList(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
  async searchObjectivePrimeList({ commit }, payload) {
      const { filter, callback } = payload;
      await searchObjectivePrimeList(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    }
  },
  mutations: {
    setObjective(state,payload){
      state.selectedObjective=payload
    },
    showObjectiveDetail(state) {
        state.openObjective = !state.openObjective;
    },
    removeCriterea(state, payload) {
      const { index } = payload
      state.selectedObjective?.criterea?.splice(index, 1);
  },
  removeCritereaDetail(state, payload) {
      const { index,i } = payload
      state.selectedObjective?.criterea[index].details?.splice(i, 1);
  },
  setCriterea(state, payload) {
      state.selectedObjective.criterea = payload;
  },
  setVendorsList(state, payload) {
    console.log(payload)
    state.vendorsList = payload;
  },
   
  },
};

export default objectiveModule;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06fca700"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card bg-white mt-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "font-size-14 d-block" }
const _hoisted_4 = { class: "card-footer d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    class: "theme-1",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    onHide: _ctx.onClose,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dialogState.message), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_btn, {
            class: "btn text-body mr-2",
            color: "transparent",
            label: _ctx.dialogState?.cancelButton ? _ctx.dialogState.cancelButton : _ctx.$t('main.confirmationDialog.cancelButton'),
            onClick: _ctx.onCancel
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_q_btn, {
            class: "btn btn-danger",
            label: _ctx.dialogState?.confirmButton ? _ctx.dialogState.confirmButton : _ctx.$t('main.confirmationDialog.confirmationButton'),
            onClick: _ctx.onConfirm
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onHide"]))
}
import { Module } from "vuex";
import {
    ScrenConfigurationState,
} from "@/types";
import { savePicklist, searchPicklist } from "@/store/services/configuration/ScrenConfigurationService";
import Notify from 'quasar/src/plugins/Notify.js';;


const screnConfigurationModule: Module<ScrenConfigurationState, unknown> = {
    namespaced: true,
    state: {
        listConfiguration: []
    },
    getters: {

    },
    actions: {
        searchPicklist({ commit }, payload) {
            const { filter, callback } = payload
            searchPicklist(filter)
              .then(res => {
                commit("setsearchPicklist",res )
                callback(res)
              })
              .catch(e => console.error(e))
          },
          savePicklist({ commit }, payload) {
            const { item, callback } = payload
            savePicklist(item)
              .then(res => {
                Notify.create({
                    message: `Succès: ${res.item} a été enregistrée!`,
                    color: "positive",
                  });
              })
              .catch(e => console.error(e))
          },


    },
    mutations: {
        setsearchPicklist(state, payload) {
            state.listConfiguration = (payload)
        }

    }
}

export default screnConfigurationModule;

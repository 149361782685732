import {
    Account,
    Address,
    BalanceSheet,
    BankAccount,
    ContactType,
    Facility,
    IContactMechanism,
    Jobs,
    PartyAddress,
    PartyClassification,
    PartyContactCorrespondent,
    PartyContactMechanism,
    PartyCorrespondent,
    PartyCorrespondentStatus,
    Person,
    Picklist,
    Relation,
    Role,
    Third,
    ThirdType,
} from "@/types";
import {
    deepClone,
    deleteAttributes,
    deleteAttributesRecursively,
    deleteNonRequiredAttributes,
    getOperator,
    globalConfig,
    headers, Masks,
    mountSelectFields,
    Paths,
    unmountSelectFieldsV2,
    UON,
    upsert,
    upsertWithDataMessageReturn,
    upsertWithDataReturn} from "@/utils";
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import store from "@/store";
import { buildPostalCode, saveFile } from "./offerService";
import { useApi } from "@/requests/useApi";
import { getThird } from "@/store/services/searchThird";
import moment from "moment/moment";
import { buildPreferences } from "@/commons/party-middleware";
import axios from "axios";
import i18n from "@/i18n";
import { saveOrganizationOrch, savePersonOrch } from "./thirdOrchestration";

const system_uid = 'odm-party'

export async function saveThird(body: Third): Promise<Third>{
  
    const populateId = (party: Third | undefined): void => {
        if (body.id?.resourceUid === '') {
            body.id = {
                resourceUid: party?.resourceUid,
                objectType: party?.objectType,
                systemUid: party?.systemUid
            }
        }
    }

    function checkTypePerson() {
        return body.id?.objectType?.includes("frenchperson")
            || body.type?.id === ThirdType.PERSON;
    }

    if (checkTypePerson() && body.person) {
        delete body.person.type
        delete body.person.status
        delete (body as any).creationDate;
        delete (body as any).addedBy
        unmountSelectFieldsV2(body.person, [
            "title",
            "nationality",
            "status",
            "currentMaritalStatus",
            "currentMatrimonialRegime",
            "periodicity",
            "gender",
            "birthplace",
            "addresses"
          ]);
          unmountSelectFieldsV2(body.roles, ["role","status"]);
          unmountSelectFieldsV2(body.classifications, ["partyClass"]);
        //const third = await savePerson(body);
        const third = await savePersonOrch(body);
        body.person = third?.person;
        populateId(body.person)

    } else if (body.organization) {
        delete body.organization.manager
        delete body.organization.type
        delete body.organization.status
        delete (body as any).creationDate;
        delete (body as any).addedBy
          unmountSelectFieldsV2(body, [
            "legalCategory",
            "activitySector",
            "partyClass",
            "codeNACE",
            "role",
            "status",
            "contacts",
            "correspondents",
            "financialExposures",
            "facilityType",
            "postalCode"
          ]);
        //const third = await saveOrganization(body);
        const third = await saveOrganizationOrch(body);
        body.organization = third?.organization;
        populateId(body.organization);
    }

    let third = {};
    const objectTypeArray = body.id?.objectType?.split('.');
    if (objectTypeArray && body.id?.resourceUid) {
        const type = `party-${objectTypeArray[objectTypeArray.length - 1]}`;
        const whenCreated = moment(new Date()).format('YYYY-MM-DD');
        third = getThird(body.id.resourceUid, type, whenCreated);
    }
    return third;
    
   
}

async function savePerson(data: Third) {
    const body: Third = JSON.parse(JSON.stringify(data))
    if (body.person) {
        if (body.roles) {
            body.person.roles = buildRole(body.roles)
        }
        if (body.addresses) {
            const promises = body.addresses.map(partyAddress => saveAddresses(partyAddress.address, body.person?.roles))
            let livingSituation = ''
            let index = 0;
            const allAddressSaved = await Promise.all(promises)
            const personAddress = []
            for (const addr of allAddressSaved) {
                if (addr) {
                    const partyAddressRelationship = body?.addresses?.filter(address => address.address.resourceUid === addr)[0];
                    if (body.person?.addresses) {

                        livingSituation = (body.addresses[index] as any).livingSituation.resourceUid
                    }
                    const partyAddress: PartyAddress = {
                        resourceUid: partyAddressRelationship?.resourceUid || undefined,
                        daaq: "/",
                        address: {
                            resourceUid: addr,
                            objectType: 'odm.party.address'
                        },
                        livingSituation: livingSituation !== '' && livingSituation !== undefined ? {
                            resourceUid: livingSituation
                        } : null,
                        objectType: 'odm.party.partyaddress'
                    }
                    personAddress.push(partyAddress)
                }
                index++
            }
            body.person.addresses = personAddress
        }
        if (body.person?.title?.resourceUid === "") {
            delete body.person.title
        }
        if (body.person.homemakers !== "") {
            buildBusinessDataPerson(body.person)
        }
        if (body.person.birthDate !== "") {
            buildBirthDatePerson(body.person)
        }
        if (body.person.nationality?.type?.value || body.person.nationality?.resourceUid) {
            buildCitizenshipsPerson(body.person)
        } else {
            delete body.person.nationality
        }
        if (body.person.birthplace?.type?.id !== "") {
            buildBirthplace(body.person)
        } else {
            delete body.person.birthplace
        }
        if (body.person.gender?.type?.value !== "") {
            buildGenderDataPerson(body.person)
        } else {
            delete body.person.gender
        }
        if (body.person.currentMaritalStatus) {
            buildSituation(body.person)
        } else {
            delete body.person.maritalStatuses
            body.person.maritalStatuses = []
        }
        if (body.person.revenues && body.person.revenues.length > 0 && body.person.revenues[0]?.analysisDate) {
            buildAnalysis(body.person)
        } else {
            delete body.person.revenues
            body.person.revenues = []
        }
        if (body.person.jobs && body.person.jobs.length > 0 && body.person.jobs[0].employerName) {
            await buildJobs(body.person, true)
            body.person.jobs?.map((job: any) => {
                if (job.jobLocation && !job.jobLocation.resourceUid) {
                    delete job.jobLocation
                }
            })
        } else {
            delete body.person.jobs
            body.person.jobs = []
        }
        delete body.person.currentMaritalStatus
        delete body.person.currentMatrimonialRegime
        delete body.person.homemakers
        delete body.person.retirementPassage
        delete body.person.birthChildren
        delete body.person.removedSituation

        body.person.roles?.forEach((role: Role) => {
            role.preferences?.push(...role.facilityPreferences);
            delete role.facilityPreferences;
        });

        if (body.classifications) {
            body.person.classifications = buildClassification(body.classifications)
        }
        if (body.financialExposures) {

            body.person.financialExposures = buildFinancialExposures(body.financialExposures)
        }
        body.person.objectType = 'odm.party.party.person.frenchperson'

        if (body.person.contacts) {
            await buildPersonContacts(body.person);
        }
        const ids = body.person.ids?.filter((el: any) => el.emissionDate !== "")
        if (ids && ids.length > 0) {
            body.person.ids?.forEach((item: any) => {
                item.emissionDate = item.emissionDate ? formatDateLocale(item.emissionDate, 'DD/MM/YYYY', 'fr') : '';
                item.validity.from = item.emissionDate;
                item.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr') : '';
                item.documentType.resourceUid = item.documentType.type || item.documentType.resourceUid;

                delete item.documentType.type
                delete item.isDisabled
            });
        } else {
            delete body.person.ids
            body.person.ids = []
        }


        if (body.person.correspondents) {
            body.person.correspondents = body.person.correspondents.filter(correspondent => {
                return correspondent.firstName !== "" && correspondent.familyName !== "" && correspondent.qualificationType.resourceUid !== "";
            });
            await buildCorrespondent(body.person.correspondents);
        }
        if (body.accounts) {
            const promises = body.accounts.map((item: Account) => saveAccounts(item.bankAccount))
            const entityList = await Promise.all(promises)
            body.person.accounts = []
            let i = 0
            for (const item of entityList) {
                if (item) {
                    const data: Account = {
                        objectType: 'odm.party.partybankaccount',
                        bankAccount: {
                            resourceUid: item,
                            objectType: 'odm.party.bankaccount'
                        },
                        daaq: '/',
                        resourceUid: body.accounts[i].resourceUid ? body.accounts[i].resourceUid : ''
                    }
                    body.person.accounts.push(data);
                    i++
                }
            }
        }

        // The reference will be separated by Person and Organization on DB - because of that, we have this peace of code in both methods PP and PM
        if (!body.person.reference || body.person.reference === '') {
            body.person.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
        }
        body.person.financialExposures?.forEach((financialExposure: any) => {
            if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
                delete financialExposure.outstandingType
            }
            if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
                delete financialExposure.financialInstitution
            }
        })

        const clone = deepClone(body);


        cleanAttribute(body.person)
        body.person = await upsertWithDataReturn(system_uid, body.person, 'frenchperson');

        if (body.person?.resourceUid) {
            await savePartyRelations(clone, body.person.resourceUid);
        }
        return body;
    }
}

function cleanAttribute(third: any) {
    delete third.relations
    delete third.codeApe
    delete third.type
    if (third.status?.resourceUid == "") {
        delete third.status
    }



}


export function buildCitizenshipsPerson(person: any) {
    person.citizenships = []
    if (person.nationality.type?.value || person.nationality.resourceUid) {
        const citizenship = {
            country: {
                resourceUid: person.nationality.type?.value || person.nationality.resourceUid,
                objectType: 'odm.party.country'
            },
            daaq: store.state.authModule?.daaq
        }
        person.citizenships.push(citizenship)
    }
    delete person.nationality
}

function buildFinancialExposures(financialExposures: any) {
    const exposures: any[] = []
    if (financialExposures && financialExposures.length > 0) {
        financialExposures.map((item: any) => {
            const exposure =
            {
                resourceUid: item.resourceUid,
                objectType: item.objectType,
                systemUid: item.systemUid,
                daaq: item.daaq,
                grossOutstanding: {
                    amount: item.grossOutstanding,
                    currency: "EUR"
                },
                netOutstanding: {
                    amount: item.netOutstanding,
                    currency: "EUR"
                },
                residualValue: {
                    amount: item.residualValue,
                    currency: "EUR"
                },
                flagFinancialPool: item.flagFinancialPool,
                flagRiskPool: item.flagRiskPool,
                shareFinancialPool: item.shareFinancialPool,
                shareRiskPool: item.shareRiskPool,
                effectiveDate: item.effectiveDate ? formatDateLocale(item.effectiveDate, 'DD/MM/YYYY', 'fr') : '',
                contractReference: item.contractReference,
                contractValidity: {
                    from: item.contractValidity.from ? formatDateLocale(item.contractValidity.from, 'DD/MM/YYYY', 'fr') : '',
                    until: item.contractValidity.until ? formatDateLocale(item.contractValidity.until, 'DD/MM/YYYY', 'fr') : '',
                },
                outstandingType: item.outstandingType?.value ? item.outstandingType?.value : item.outstandingType,
                financialInstitution: item.financialInstitution?.value ? item.financialInstitution?.value : item.financialInstitution,

            }
            exposures.push(exposure)
        })

        return exposures;
    }
    else {
        return []
    }

}

export function buildBirthDatePerson(person: any) {
    person.birthDate = person.birthDate ? formatDateLocale(person.birthDate, 'DD/MM/YYYY', 'fr') : moment().format(Masks.dateMask)
    delete person.age
}

export function buildBusinessDataPerson(person: any) {
    const businessDataPerson = {
        homemakers: person.homemakers,
        retirementPassage: person.retirementPassage,
        birthChildren: person.birthChildren,
    }
    person.businessData = businessDataPerson
}
export function buildGenderDataPerson(person: any) {
    const gender = {
        resourceUid: person.gender.resourceUid,
        objectType: 'odm.party.gender',
    }
    delete person.gender
    person.gender = gender
}

export function buildBirthplace(person: any) {
    const birthplace: any = {
        resourceUid: person.birthplace.resourceUid || person.birthplace.postalCode,
        objectType: 'odm.party.postalcode.frenchpostalcode'
    }
    delete person.birthplace
    person.birthplace = birthplace
}

export function buildSituation(person: any) {
    let maritalStatusesUID = null
    if (person.maritalStatuses[0] && person.maritalStatuses[0].resourceUid) {
        maritalStatusesUID = person.maritalStatuses[0].resourceUid
    }
    person.maritalStatuses = []
    if(!!person.currentMaritalStatus.resourceUid && person.currentMaritalStatus.resourceUid === "SINGLE"){
        const maritalStatuses = {
            maritalStatus: {
                resourceUid: person.currentMaritalStatus.resourceUid,
                objectType: 'odm.party.maritalstatus',
            },
            matrimonialRegime: {
                resourceUid: 'NONE',
                objectType: 'odm.party.matrimonialregime',
            },
            daaq: store.state.authModule?.daaq,
            resourceUid: maritalStatusesUID
        }
        person.maritalStatuses.push(maritalStatuses)
    }else if (!!person.currentMaritalStatus.resourceUid && !!person.currentMatrimonialRegime.resourceUid) {
        let maritalStatuses: any = {}
        if (maritalStatusesUID) {
            maritalStatuses = {
                maritalStatus: {
                    resourceUid: person.currentMaritalStatus.resourceUid,
                    objectType: 'odm.party.maritalstatus',
                },
                matrimonialRegime: {
                    resourceUid: person.currentMatrimonialRegime.resourceUid,
                    objectType: 'odm.party.matrimonialregime',
                },
                daaq: store.state.authModule?.daaq,
                resourceUid: maritalStatusesUID

            }
        } else {
            maritalStatuses = {
                maritalStatus: {
                    resourceUid: person.currentMaritalStatus.resourceUid,
                    objectType: 'odm.party.maritalstatus',
                },
                matrimonialRegime: {
                    resourceUid: person.currentMatrimonialRegime.resourceUid,
                    objectType: 'odm.party.matrimonialregime',
                },
                daaq: store.state.authModule?.daaq,
                resourceUid: maritalStatusesUID

            }
        }

        person.maritalStatuses.push(maritalStatuses)
    }
    delete person.currentMaritalStatus
    delete person.currentMatrimonialRegime
}

export function buildAnalysis(person: any) {
    const analyse: any = []
    person
        .revenues
        .filter((revenue: any) => revenue.analysisDate !== "" && !revenue.resourceUid)
        .forEach((revenue: any) => {
            const detailAnalyse: any = []
            revenue.detail.forEach((detailItem: any) => {
                if (detailItem.nature.resourceUid || detailItem.nature.type) {
                    const detail = {
                        nature: {
                            resourceUid: detailItem.nature.resourceUid || detailItem.nature.type,
                            objectType: 'odm.party.expenseincometype'
                        },
                        periodicity: {
                            resourceUid: detailItem.periodicity.resourceUid,
                            objectType: 'odm.party.expenseincomeperiodicity'
                        },
                        value: {
                            currency: "EUR",
                            amount: detailItem.value
                        },
                        label: detailItem.type,
                        validity: {
                            from: detailItem.validity.from ? formatDateLocale(detailItem.validity.from, 'YYYY-MM-DD', 'fr') : '',
                            until: detailItem.validity.until ? formatDateLocale(detailItem.validity.until, 'YYYY-MM-DD', 'fr') : '',
                        },
                        daaq: store.state.authModule?.daaq
                    }
                    detailAnalyse.push(detail)
                }
            })
            analyse.push({
                label: '',
                analysisDate: revenue.analysisDate ? formatDateLocale(revenue.analysisDate, 'YYYY-MM-DD', 'fr') : '',
                status: {
                    resourceUid: 'NEW',
                    objectType: 'odm.party.revenuesanalysisstatus'
                },
                yearlyExpensesTotal: {
                    currency: "EUR",
                    amount: 0
                },
                yearlyIncomeTotal: {
                    currency: "EUR",
                    amount: 0
                },
                daaq: store.state.authModule?.daaq,
                detail: detailAnalyse
            })
        })
    delete person.analysis
    deleteAttributesRecursively(person.revenues, ['type'])
    if (analyse.length > 0) {
        person.revenues = analyse
    }

    person
        .revenues
        .filter((r: any) => !!r.resourceUid)
        .forEach((r: any) => {
            delete r.isDisabled
            r.analysisDate = r.analysisDate ? formatDateLocale(r.analysisDate, 'YYYY-MM-DD', 'fr') : '',
                r.detail.forEach((d: any) => {
                    d.daaq = store.state.authModule?.daaq,
                        d.value = {
                            currency: "EUR",
                            amount: d.value
                        },
                        d.validity = {
                            from: d.validity.from ? formatDateLocale(d.validity.from, 'YYYY-MM-DD', 'fr') : '',
                            until: d.validity.until ? formatDateLocale(d.validity.until, 'YYYY-MM-DD', 'fr') : '',
                        }
                })
        })
}

export async function buildJobs(person: any, saving?: boolean, daaqResourceUid?: string) {
    const jobsPerson: any = []
    let addressId: any
    let address: any
    const jobsList = person.jobs.filter((item: any) => !!item.employerName)
    for (const el of jobsList) {
        if (saving) {
            unmountSelectFieldsV2(el, ['occupationType', 'activity', 'positionType', 'contractType'])
            if (el.jobLocation) {
                deleteAttributes(el.jobLocation, ['type']);
            }
            addressId = el.jobLocation && el.jobLocation.street?.length > 0 ? await saveAddresses(el.jobLocation) : null;
        } else {
            mountSelectFields(el, ['occupationType', 'activity', 'positionType', 'contractType'])
           /* if (el.jobLocation) {
                const result = await useApi(UON.SystemUid.odmParty, 'address').get(el.jobLocation.resourceUid);
                if (result.response) {
                    address = result.response
                    el.jobLocation.postalCode = await buildPostalCode(address.postalCode.resourceUid);
                }
            }*/
        }

        jobsPerson.push({
            occupationType: (el.occupationType?.resourceUid || el.occupationType?.type?.value) ? el.occupationType : null,
            activity: el.activity,
            positionType: el.positionType,
            employerName: el.employerName,
            nbHour: el.nbHour,
            validity: {
                from: el.validity?.from ? saving ? isDateValid(el.validity?.from) ? formatDateLocale(el.validity?.from, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.validity?.from, 'YYYY-MM-DD', 'fr')  : el.validity?.from : '',
                until: el.validity?.until ? saving ? isDateValid(el.validity?.until) ? formatDateLocale(el.validity?.until, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.validity?.until, 'YYYY-MM-DD', 'fr')   : el.validity?.until : '',
            },
            employerCreationDate: el.employerCreationDate ? saving ? isDateValid(el.employerCreationDate) ? formatDateLocale(el.employerCreationDate, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.employerCreationDate, 'YYYY-MM-DD', 'fr')  : el.employerCreationDate : '',
            principal: el.principal,
            tenureDate: el.tenureDate ? saving ?  isDateValid(el.tenureDate) ? formatDateLocale(el.tenureDate, 'DD/MM/YYYY', 'fr') : formatDateLocale(el.tenureDate, 'YYYY-MM-DD', 'fr') : el.tenureDate : '',
            daaq: daaqResourceUid ?? '/',
            resourceUid: el.resourceUid || undefined,
            contractType: (el.contractType?.resourceUid || el.contractType?.type?.value) ? el.contractType : null,
            jobLocation: el.jobLocation ? (saving && addressId !== null) ? {
                objectType: "odm.party.address",
                systemUid: "odm-party",
                resourceUid: addressId
            } : {
                postalCode: {
                    resourceUid: el.jobLocation.postalCode ? el.jobLocation.postalCode.resourceUid : null,
                    type: el.jobLocation.postalCode && el.jobLocation.postalCode.type ? el.jobLocation.postalCode.type : null,
                    country: {
                        name: (el.jobLocation.postalCode && el.jobLocation.postalCode.country) && (el.jobLocation.postalCode.country.name ||  el.jobLocation.postalCode.country.resourceUid) ? el.jobLocation.postalCode.country.name || el.jobLocation.postalCode.country.resourceUid : '',
                    },
                    city: el.jobLocation.postalCode && el.jobLocation.postalCode.city ? el.jobLocation.postalCode.city : '',
                    postalcode:el.jobLocation.postalCode ? el.jobLocation.postalCode.resourceUid : null

                },
                street: address?.street,
                streetNum: address?.streetNum
            } : {
                postalCode: {
                    type: '',
                    country: {
                        name: ''
                    },
                    city: ''

                },
                street: '',
                streetNum: ''
            }
        })
    }
    delete person.jobs
    if (jobsPerson.length > 0) {
        person.jobs = jobsPerson
    }
    return person
}

function isDateValid(dateString: any) {
    
    // Expression régulière for the  format "DD/MM/YYYY"
    const regexDMY = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    
    return regexDMY.test(dateString) 
}

/*const returnCorrectDate = (date: string, saving: boolean) => {
    return date ? saving ? useDate.sqlFormat(date) : date : '';
    return () => {
        if ( date ) {
            if ( saving ) {
                return useDate.sqlFormat(date)
            }
            else {
                return date
            }
        }
        else return ''
    }
}*/

async function buildCorrespondent(correspondents: PartyCorrespondent[]) {
    for (const c of correspondents) {
        c.status.resourceUid = PartyCorrespondentStatus.ACTIVE
        const promises = c.contactCorrespondent.map((item: PartyContactCorrespondent) => saveContactCorrespondent(item))
        await Promise.all(promises)
        deleteAttributes(c, ['isDisabled'])
    }
}

function checkIfPostalCodesAreFulfilled(addresses: PartyAddress[]) {
    return addresses.filter(adr => adr.address.postalCode?.postalCode !== '').length === addresses.length;
}

async function saveOrganization(body: Third) {
    if (body.organization) {

        body.organization.objectType = 'odm.party.party.organization.frenchorganization'
        if (body.roles) {
            body.organization.roles = buildRole(body.roles)
        }
        if (body.organization.facilities) {
            await buildFacilities(body.organization.facilities, body.organization.roles);
        }
        if (body.organization.contacts) {
            await buildPartyContacts(body.organization.contacts);
        }
        if (body.classifications) {
            body.organization.classifications = buildClassification(body.classifications)
        }
        if (body.financialExposures) {
            body.organization.financialExposures = buildFinancialExposures(body.financialExposures)
        }
        if (body.addresses && checkIfPostalCodesAreFulfilled(body.addresses)) {

            body.addresses.forEach(adresse => {
                delete adresse.livingSituation
            });

            const promises = body.addresses.map((partyAddress: PartyAddress) => savePartyAddresses(partyAddress, body.organization?.roles))
            const allPartyAddressSaved = await Promise.all(promises)
            if (body.organization.addresses) {
                body.organization.addresses.splice(0, body.organization.addresses.length)
            }
            body.organization.addresses?.push(...allPartyAddressSaved);

        }


        if (body.accounts) {
            const promises = body.accounts.map((item: Account) => saveAccounts(item.bankAccount))
            const entityList = await Promise.all(promises)
            body.organization.accounts = []
            let i = 0
            for (const item of entityList) {

                const data: Account = {
                    objectType: 'odm.party.partybankaccount',
                    bankAccount: {
                        resourceUid: item,
                        objectType: 'odm.party.bankaccount'
                    },
                    daaq: "/",
                    resourceUid: body.accounts[i].resourceUid ? body.accounts[i].resourceUid : ''
                }
                body.organization.accounts.push(data);
                i = i++
            }
        }

        if (body.organization.correspondents) {
            await buildCorrespondent(body.organization.correspondents);
        }
        if (body.organization.activitySector) {
            buildActivitySector(body.organization);
        }

        if (body.organization.balanceSheets) {
            for (const item of body.organization.balanceSheets) {

                let status = '';
                if (item.status?.id) {
                    status = item.status?.id
                }
                if (item.status?.value) {
                    status = item.status?.value
                }
                const country = item.country?.id;
                item.status = {
                    objectType: 'odm.party.balancesheetstatus',
                    resourceUid: status
                };
                item.country = {
                    objectType: 'odm.party.country',
                    resourceUid: country
                };

                if (item.period) {
                    item.period.until = item.period.until ? formatDateLocale(item.period.until, 'DD/MM/YYYY', 'fr') : ''
                    item.period.from = item.period.from ? formatDateLocale(item.period.from, 'DD/MM/YYYY', 'fr') : ''
                }
                if (item.detail) {
                    item.detail.forEach((dt: any) => {
                        const nature = dt.nature.label;
                        dt.nature = {
                            objectType: 'odm.party.balanceitemtype',
                            resourceUid: nature
                        };
                        dt.objectType = 'odm.party.balanceitem';
                        
                    });
                }
                item.daaq = '/'
                if (item.supportingDocument && !item.supportingDocument.resourceUid) {
                  
                    item.supportingDocument = await saveFile(item.supportingDocument).then((res) => {
                        return {
                            resourceUid: res.resourceUid,
                            objectType: "odm.filemngt.file",
                            systemUid: 'odm-filemngt'
                        }
                    })
                }
                else{
                    deleteNonRequiredAttributes(item.supportingDocument, ["objectType", "resourceUid", "system_uid"]);
                    item.supportingDocument.systemUid= 'odm-filemngt'
                }
            }
        }
        if (!body.organization.legalCategory?.resourceUid) {
            delete body.organization.legalCategory
        }

        // The reference will be separated by Person and Organization on DB - because of that, we have this peace of code in both methods PP and PM
        if (!body.organization.reference || body.organization.reference === '') {
            body.organization.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
        }
        formatCreationDate(body.organization)
        cleanAttribute(body.organization)
        body.organization.roles?.forEach((role: Role) => {
            role.preferences?.push(...role.facilityPreferences);
            delete role.facilityPreferences;
        });

        body.organization.financialExposures?.forEach((financialExposure: any) => {
            if (financialExposure.outstandingType.value === "") {
                delete financialExposure.outstandingType
            }
            if (financialExposure.financialInstitution.value === "") {
                delete financialExposure.financialInstitution
            }

        })

        body.organization = await upsertWithDataReturn(system_uid, body.organization, 'frenchorganization')

        if (body.organization?.resourceUid) {
            body.relations = await savePartyRelations(body, body.organization.resourceUid);
        }
    }
    return body;
}




const formatCreationDate = (organization: any) => {
    organization.creationDate = organization.creationDate ? formatDateLocale(organization.creationDate, 'DD/MM/YYYY', 'fr') : null
}
const buildActivitySector = (organization: any) => {

    if (organization.activitySector.resourceUid) {
        deleteNonRequiredAttributes(organization.activitySector, ['objectType', 'systemUid', 'resourceUid']);
    }
    else {

        organization.activitySector = null

    }

}


export function buildRole(roles: any) {
    const r: any = []
    roles.forEach((role: any) => {
        r.push({
            resourceUid: role.resourceUid,
            role: {
                resourceUid: role.role?.resourceUid,
                objectType: 'odm.party.role'
            },
            status: {
                resourceUid: role.status?.resourceUid || 'ACTIVATED',
                objectType: 'odm.party.rolestatus'
            },
            validity: {
                from: role.validity?.from ? formatDateLocale(role.validity.from, 'DD/MM/YYYY', 'fr') : formatDateLocale(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY', 'fr'),
                until: role.validity?.until ? formatDateLocale(role.validity.until, 'DD/MM/YYYY', 'fr') : '',
            },
            nonSolicitation: role.nonSolicitation,
            daaq: "/",
            preferences: [],
            facilityPreferences: [],
            objectType: 'odm.party.partyrole'
        })
    })

    return r
}

function buildClassification(classifications: PartyClassification[]) {
    const classif: PartyClassification[] = []
    classifications.filter((item: PartyClassification) => !!item.partyClass?.resourceUid).forEach((classification: any) => {
        const partyClassification: any = {
            objectType: "odm.party.partyclassification",
            partyClass: {
                resourceUid: classification.partyClass?.resourceUid,
                objectType: "odm.party.partyclass",

            },
            validity: {
                from: classification.validity?.from ? formatDateLocale(classification.validity.from, 'DD/MM/YYYY', 'fr') : '',
                until: classification.validity?.until ? formatDateLocale(classification.validity.until, 'DD/MM/YYYY', 'fr') : '',
            },
            daaq: "/",
            classificationDate: classification.classificationDate ? formatDateLocale(classification.classificationDate, 'DD/MM/YYYY', 'fr') : '',

        }
        if (classification.resourceUid) {
            partyClassification.resourceUid = classification.resourceUid
        }
        classif.push(partyClassification)
    })
    return classif
}

export async function buildPersonContacts(person: Person) {
    const contacts = person.contacts?.filter((partyContactMechanism: PartyContactMechanism) => {
        const contactMechanism = partyContactMechanism.contactMechanism;
        return contactMechanism.emailAddress !== ""
            || contactMechanism.phoneNumber !== ""
            || contactMechanism.identifier !== ""
    })
    if (contacts && contacts.length > 0) {
        const promises = contacts.map((item: PartyContactMechanism) => saveContactMechanism(item.contactMechanism))
        await Promise.all(promises)
        person.contacts?.forEach((contact: PartyContactMechanism) => {
            deleteNonRequiredAttributes(contact, ["resourceUid", "contactMechanism", "daaq"]);
        })
    } else {
        delete person.contacts
        person.contacts = []
    }
}

export async function buildPartyContacts(contacts: PartyContactMechanism[]) {
    const promises = contacts.map(contact => saveContactMechanism(contact.contactMechanism))
    await Promise.all(promises)
    contacts.forEach(contact => {
        deleteNonRequiredAttributes(contact, ["resourceUid", "contactMechanism"])
        contact.daaq = "/"
    })
}

export async function saveAccounts(item: BankAccount) {
    const body: BankAccount = { ...item }
    const status = body.status?.id
    const type = body.type?.id
    delete body.status
    delete body.type
    delete body.isPreferred
    body.status = { resourceUid: status }
    body.type = { resourceUid: type }
    if (item.validity) {
        body.validity = { until: '', from: '' }
        body.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr') : ''
        body.validity.from = item.validity.from ? formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr') : ''
    }
    body.iban = item.iban?.split(' ').join('')
    if (body.bic) {
        body.bic = body.bic.endsWith('XXX') ? body.bic : body.bic + 'XXX'
    }
    const responseData = await upsertWithDataMessageReturn(system_uid, body.iban, 'validateIban')

    if (responseData?.message === 'IBAN is correct') {
        return upsert(system_uid, body, 'frenchbankaccount')
    }
}

async function saveAddresses(address: Address, roles?: Role[], isFacility?: boolean) {
    delete address.isDisabled;
    if (address.postalCode && !address.postalCode.resourceUid) {
        address.postalCode.resourceUid = address.postalCode.type?.value
    }
    if (address.postalCode && address.postalCode.type && address.postalCode.type.value) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }

    if (address.postalCode) {
        deleteNonRequiredAttributes(address.postalCode, ["resourceUid"]);
    }


    if (address.street || (address.postalCode?.resourceUid)) {
        const clonedAddress = deepClone(address);

        const isString = typeof address.type?.resourceUid === 'string';
        if (!isString) {
            delete address.type;
        }

        const addressId = await upsert(system_uid, address, 'address');
        if (roles && roles.length > 0 && !isString) {
            for (const role of roles) {
                const preferences = isFacility ? role.facilityPreferences : role.preferences;
                const params = {
                    preferences: preferences,
                    partyAddress: clonedAddress,
                    addressUID: addressId
                }
                if (isFacility) {
                    role.facilityPreferences = await buildPreferences(params);
                }
                else {

                    const rolePreference = await buildPreferences(params);
                    role.preferences = rolePreference;

                }
            }

        }
        return addressId;
    }
}

export async function savePartyAddresses(item: PartyAddress, roles?: any) {
    const addressId = await saveAddresses(item.address, roles);

    item.address = {
        resourceUid: addressId,
        objectType: 'odm.party.address',
    }

    item.objectType = 'odm.party.partyaddress'
    item.daaq = "/"

    deleteNonRequiredAttributes(item, ["resourceUid", "objectType", "address", "daaq"]);

    return item;
}

async function buildFacilities(body: any[], roles?: Role[]) {
    let index = 0
    for (const facility of body) {
        if (facility.siret) {
            delete facility.isDisabled;
            delete facility.livingSituation;
            facility.daaq = '/'
            const partyAddresses = new Array<PartyAddress>();
            partyAddresses[0] = {
                daaq: "/",
                address: facility.address
            };

            if (facility.address && checkIfPostalCodesAreFulfilled(partyAddresses)) {
                facility.address.resourceUid = await saveAddresses(facility.address, roles, true);
                deleteNonRequiredAttributes(facility.address, ["objectType", "systemUid", "resourceUid"]);
            }
            if (facility.address?.resourceUid === undefined) {
                facility.address = null;
            }

        } else {
            body.splice(index, 1);
        }
        index++
    }
}

async function buildPartyRelations(relations: Relation[]) {
    const savedRelations = [];
    for (const relation of relations) {
        const { response } = await useApi(UON.SystemUid.odmParty, Paths.partyRelation).upsert(relation);
        savedRelations.push(response);
    }
    return savedRelations;
}

const saveContactMechanismType = async (contactMechanism: IContactMechanism, type: string) => {
    let parentResourceUid = Paths.contactMechanism.parentResourceUid.get(type)
    Object.values(ContactType).forEach(contactType => {
        if (contactType === type) {
            parentResourceUid = Paths.contactMechanism.parentResourceUid.get(type)
        }
    })
    if (parentResourceUid) {
        delete contactMechanism.blocked
        return await upsert(system_uid, contactMechanism, parentResourceUid)
    }
}

async function saveContactMechanism(contactMechanism: IContactMechanism) {
    if (contactMechanism){
    const typeId = contactMechanism.type?.id
    if (contactMechanism.type?.config) {
        contactMechanism.objectType = contactMechanism.objectType ? contactMechanism.objectType : contactMechanism.type?.config.objectType;
        if (contactMechanism.type?.config.fields) deleteNonRequiredAttributes(contactMechanism, contactMechanism.type?.config.fields);
    }
    if (typeId) {
        deleteAttributesRecursively(contactMechanism, ['type'])
        contactMechanism.resourceUid = await saveContactMechanismType(contactMechanism, typeId);
    }
    deleteNonRequiredAttributes(contactMechanism, ["objectType", "resourceUid"]);
    }
    return contactMechanism;
}

export async function saveContactCorrespondent(item: PartyContactCorrespondent) {
    await saveContactMechanism(item.contactMechanism)
    deleteNonRequiredAttributes(item, ["objectType", "resourceUid", "contactMechanism", "daaq"]);
    if (!item?.daaq) item.daaq = '/';
    return item;
}

async function savePartyRelations(body: Third, resourceUid: string) {
    if (body.relations) {

        const relations = body.relations.filter(item => item.relationType?.resourceUid !== '');

        relations
            .forEach(item => {
                item.relation = { resourceUid: item.relation?.type?.value }
                if (item.validity) {
                    item.validity.from = formatDateLocale(item.validity?.from, 'DD/MM/YYYY', 'fr')
                    if (item.validity.from === 'Invalid date') {
                        item.validity.from = formatDateLocale(formatDate(new Date(), '-'), 'DD/MM/YYYY', 'fr');
                    }
                    if (item.validity.until && item.validity.until !== 'Invalid date') {
                        item.validity.until = formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr');
                    }
                }
                if (item.partyAsc?.resourceUid === "") {
                    item.partyAsc.resourceUid = resourceUid
                }
                if (item.partyDesc?.resourceUid === "") {
                    item.partyDesc.resourceUid = resourceUid
                }

                deleteNonRequiredAttributes(item.relationType, ['objectType', 'resourceUid']);
                deleteNonRequiredAttributes(item, ['objectType', 'systemUid', 'resourceUid', 'relationType', 'partyDesc', 'partyAsc', 'validity', 'sharingRate']);
            });

        const uid = store.getters["thirdModule/getDeletedRelationUid"];
        if (uid) {
            await useApi(UON.SystemUid.odmParty, Paths.partyRelation).remove(uid);
            store.commit("thirdModule/setDeletedRelationUid", undefined);
        }

        return await buildPartyRelations(relations);
    }
}

export const getPartyRelations = (resourceUid: string) => new Promise((resolve, reject) => {
    return api().get(settings.api_query_url, {
        headers: headers({
            qc: JSON.stringify({
                queryId: 'third-relations',
                offset: 0,
                limit: 100,
                parameters: { _resourceUid: resourceUid }
            })
        })
    }).then(response => {
        resolve(response.data);
    }).catch(err => {
        //FIXME: until debizium is fixed
        //reject(err)

        resolve([])
    })
})
export function getPartyClass(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-class-picklist',
                limit: 50,
                offset: 0
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.class_type_code != null) {
            operators.push(getOperator("party_party_class", "class_type_code", "EQUAL", `'${filter.class_type_code}'`, "class_type_code"))
            if(filter.id !== undefined){
                operators.push(getOperator("party_party_class", "id", "LIKE", globalConfig.formatters.getEqualValue(filter.id + '%'), "id"))
            } 
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then((res) => {
            const response = {
                systemUid: 'odm_party',
                items: res.data.map((resItem: any) => {
                    return {
                        value: resItem.id,
                        label: i18n.global.t(`demand.party.partyClass.${resItem.id}`),
                    };
                }),
            };
            resolve(response);
        })
            .catch((err) => reject(err));


    })
}
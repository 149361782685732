
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import {getOrderFromState} from "@/utils";
import OrderSummaryAsset from "@/components/summary-panel/order/OrderSummaryAsset.vue";
import OrderSummaryParty from "@/components/summary-panel/order/OrderSummaryParty.vue";
import OrderSummarySupplier from "@/components/summary-panel/order/OrderSummarySupplier.vue";
import SummaryMessage from "@/components/summary-panel/SummaryMessage.vue";
import OrderSummaryPreConditions from "@/components/summary-panel/order/OrderSummaryPreConditions.vue";
import OrderSummaryConditions from "@/components/summary-panel/order/OrderSummaryConditions.vue";
//import OrderSummary from "@/components/summary-panel/order/OrderSummary.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import router from "@/router";
import { TaskModule } from "@/types";
import OrderSummary from "./OrderSummary.vue";

@Options({
  components: {
    OrderSummaryParty,
    OrderSummaryAsset,
    OrderSummarySupplier,
    SummaryMessage,
    OrderSummaryPreConditions,
    OrderSummaryConditions,
    OrderSummary
  },
})
export default class OrderSummaryPanel extends Vue {
  summarySelected = "SUMMARY";
  order: any;

  get isTaskModuleOrder() {
    return store.getters['taskModule/isTaskModuleOrder']
  }

  get assets() {
    return store.getters['summaryPanelModule/getOrderAssets']
  }

  get parties() {
    return store.getters['summaryPanelModule/getOrderParties']
  }

  get summaryPanel() {
    return store.state.middleOfficeModule.summaryPanel;
  }

  get tabSelected() {
    return this.summarySelected ? this.summarySelected : "PARTY";
  }
  get orderConditions() {
    return this.$route.name === 'order' || this.$route.name === 'order-edit'
            ? store.state.orderModule.order.orderConditions
            : this.$route.name === 'TaskDetail'
            ? store.state.taskModule.order?.orderConditions
            : this.$route.name === 'task-list'
            ? store.state.summaryPanelModule.order?.orderConditions
            : null;
  }
   get orderSummary() {
    return store.state.summaryPanelModule.order;
  }
  setSummary(summaryBlock: string) {
    this.summarySelected = summaryBlock;
  }

  openSummaryPanel() {
    store.state.middleOfficeModule.summaryPanel = !store.state.middleOfficeModule
      .summaryPanel;
  }

  async openNewTab() {
    const routeData = this.$route.name === 'order' 
                      ? router.resolve(`${'/order/edit/'}${store.state.orderModule.order.resourceUid}`)
                      : this.$route.name === 'task-list'
                      ? router.resolve(`${'/order/edit/'}${store.state.summaryPanelModule.order?.resourceUid}`)
                      : router.resolve(`${'/order/edit/'}${this.order?.resourceUid}`);
        window.open(routeData.href, "_blank");

  }
  get assignReassign() {
    return store.state.taskModule.assignReassign;
  }

  created() {
        if (this.isTaskModuleOrder) {
          
          this.order = getOrderFromState(store.state, this.$route.name);
          if (this.order?.resourceUid) {
            store.dispatch("summaryPanelModule/setOrder", { orderId: this.order?.resourceUid });
          }
          if (this.$route.name === 'order') {
          
            const orderId = store.state.orderModule.selectedSearchOrderItem[0].orderid
            store.dispatch("summaryPanelModule/setOrder", { orderId: orderId });
            this.order = store.state.orderModule.order
          }
          if (this.$route.name === 'task-list' ) {
            const orderId = this.assignReassign?.selectedTasks[0].variables.orderId.value
            store.dispatch("summaryPanelModule/setOrder", { orderId: orderId });
            this.order = store.state.summaryPanelModule.order
          }
    }
  }

}
